import React from "react";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import TablePagination from "@material-ui/core/TablePagination";

const ClebreTablePagination = (props) => {
  const { setLimit, setPage, page, limit, totalRecords } = props;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  return (
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      labelRowsPerPage={translateValue(props.intl, "table.rowsPerPage")}
      labelDisplayedRows={({ from, to, count }) => {
        return ` ${from} - ${to} ${translateValue(props.intl, "of")} ${count}`;
      }}
      page={page}
      rowsPerPage={limit}
      count={totalRecords}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleRowsPerPage}
    />
  );
};

export default injectIntl(ClebreTablePagination);
