import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useHistory, Link } from "react-router-dom";
import { HttpService } from "../../http-service/HttpService";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import { connectComponent } from "../../actions/ActionUtils";
import { makeStyles } from "@material-ui/core/";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField/TextField";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import { loginValidationSchema } from "./loginValidationSchema";
import GoogleAuthButton from "../../component/googleAuth/GoogleAuthButton";
import AuthWrapper from "../AuthWrapper";
import PasswordInput from "../../component/input/PasswordInput";
import { loginErrors } from "./loginErrors";
import { registerErrors } from "../register/registerErrors";

const LoginPage = ({ intl, activationFailed, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const [loginError, setLoginError] = useState(null);
  const [loginInfo, setLoginInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [emailValue, setEmailValue] = useState("");

  useEffect(() => {
    if (activationFailed) {
      setLoginError(registerErrors.activationFailed.message);
    }
  }, [setLoginError, activationFailed]);

  const handleEmailChange = (event) => {
    formik.handleChange(event);
    setEmailValue(event.target.value);
  };

  const handleEmailLogin = (values, resetForm, setFieldValue) => {
    setLoading(true);
    HttpService.loginWithEmail({
      email: values.email,
      password: values.password,
    })
      .then((user) => {
        props.actions.userLoggedIn(user);
        setLoading(false);
        resetForm();
      })
      .catch((error) => {
        if (error.message === loginErrors.userNotActive.status) {
          setLoginError(loginErrors.userNotActive.message);
        } else if (error.message === loginErrors.classifier.status) {
          props.actions.userLoggedOut();
          setLoginInfo(loginErrors.classifier.message);
        } else {
          setLoginError(loginErrors.genericError.message);
        }
        props.actions.userLoggedOut();
        setLoading(false);
        resetForm();
        setFieldValue("email", emailValue);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values, { resetForm, setFieldValue }) => {
      setLoginError(null);
      setLoginInfo(null);
      handleEmailLogin(values, resetForm, setFieldValue);
    },
    validationSchema: loginValidationSchema(intl),
    validateOnMount: true,
    isInitialValid: false,
  });

  return (
    <AuthWrapper type="login">
      <GoogleAuthButton
        label={translateValue(intl, "login.withGoogle")}
        setError={setLoginError}
        setInfo={setLoginInfo}
        setLoading={setLoading}
        resetForm={formik.resetForm}
      />
      <Typography variant="h6" align="center">
        {translateValue(intl, "login.withEmail")}
      </Typography>
      {loginError && (
        <Alert className={classes.alert} severity="error">
          {translateValue(intl, `login.error.${loginError}`)}
        </Alert>
      )}
      {loginInfo && (
        <Alert className={classes.alert} severity="info">
          {translateValue(intl, `login.error.${loginInfo}`)}
        </Alert>
      )}
      {loading && <CircularProgress />}
      <TextField
        name="email"
        variant="outlined"
        required
        fullWidth
        disabled={loading}
        label={translateValue(intl, "email")}
        value={formik.values.email}
        onChange={handleEmailChange}
        onBlur={formik.handleBlur}
        error={formik.errors.email && formik.touched.email}
        helperText={formik.errors.email && formik.touched.email ? formik.errors.email : null}
      />
      <PasswordInput
        disabled={loading}
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.errors.password && formik.touched.password}
        helperText={
          formik.errors.password && formik.touched.password ? formik.errors.password : null
        }
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!formik.isValid || loading}
        onClick={formik.handleSubmit}
      >
        {translateValue(intl, "signIn")}
      </Button>
      <Link to="/forgot-password" className={classes.link}>
        {translateValue(intl, "login.forgotPassword")}
      </Link>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        className={classes.registerButton}
        onClick={() => history.push(`/register`)}
      >
        {translateValue(intl, "signUp")}
      </Button>
    </AuthWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  registerButton: {
    color: theme.palette.common.white,
  },
  alert: {
    width: "100%",
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

export default injectIntl(connectComponent(LoginPage));
