import { validateRequiredFiles } from "./validateRequiredFIles";
import { checkRejectedFiles } from "./checkRejectedFiles";
import { maxFileSize } from "../constants";

export const validateSync = ([validationState, processedFiles]) => {
  // Filter out wrong extensions
  validateExtensionAndSize(processedFiles, validationState);
  processedFiles = processedFiles.filter((file) => {
    return file.extension in validationState.requiredFiles;
  });

  // Add required files to state
  updateRequiredFiles(validationState, processedFiles);

  // Validate required files added to state
  validateRequiredFiles(validationState);

  // Check the reason why files were dropped during validation
  checkRejectedFiles(processedFiles, validationState);

  return validationState;
};

const validateExtensionAndSize = (processedFiles, validationState) => {
  processedFiles.forEach((file) => {
    if (file.file.size > maxFileSize) {
      validationState.droppedFiles.tooBig.push(file);
      return;
    }

    if (!(file.extension in validationState.requiredFiles)) {
      validationState.droppedFiles.wrongExtension.push(file);
      return;
    }
  });
};

const updateRequiredFiles = (validationState, files) => {
  files.forEach((file) => {
    // Only files with the same name as JSN are added
    if (
      validationState.requiredFiles["JSN"].file &&
      file.name !== validationState.requiredFiles["JSN"].name
    ) {
      return;
    }

    validationState.requiredFiles[file.extension].name = file.name;
    validationState.requiredFiles[file.extension].file = file.file;
  });
};
