import React, { Component } from "react";
import _ from "lodash";
import { Typography, withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { injectIntl } from "react-intl";
import ClebreDetailsColumn from "../../../component/examination-details/ClebreDetailsColumn";
import ClebreDetailsImage from "../../../component/examination-details/ClebreDetailsImage";
import { connectComponent } from "../../../actions/ActionUtils";
import { HttpService } from "../../../http-service/HttpService";
import { translateValue } from "../../../i18n/ClebreTranslator";
import { formatDurationInSeconds } from "../../../util/Formatter";
import { renderWithNarrowedDecimalPoints } from "../../../util/helpers";

class SegmentationResults extends Component {
  static EPISODE_TYPES_SEGMENTATION_ORDER = ["SLEEP", "BODY_POSITION", "BREATH", "APNEA"];

  static EPISODE_SUBTYPES = ["SENSOR"];

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      episodesToVisualize: null,
      envelopeData: [],
    };
  }

  componentDidMount() {
    Promise.all([
      HttpService.getEpisodesToVisualize(
        this.props.examinationData.sensorSerialNumber,
        this.props.examinationData.recordId,
        SegmentationResults.EPISODE_TYPES_SEGMENTATION_ORDER,
        SegmentationResults.EPISODE_SUBTYPES
      ),
      HttpService.getEnvelope(
        this.props.examinationData.sensorSerialNumber,
        this.props.examinationData.recordId
      ),
    ]).then(([episodesToVisualize, envelopeData]) => {
      this.setState({
        episodesToVisualize,
        loading: false,
        envelopeData,
      });
    });
  }

  renderBreathAlgorithmDetails() {
    const {
      normalBreathEpisodesCount,
      snoreEpisodesCount,
      meanRespiratoryRate,
      respiratoryRateVariability,
      snoringIndex,
      supineSnoringIndex,
      nonSupineSnoringIndex,
    } = this.props.examinationData.sleepScore;
    return this.props.examinationData ? (
      <>
        <Grid container className={this.props.classes.examinationDetailCenter}>
          <Grid item xs={3}>
            <ClebreDetailsImage
              image={"images/breath-icon.svg"}
              firstLabel={"segmentationAlgorithm.breath"}
            />
          </Grid>
          <Grid item xs={3}>
            <ClebreDetailsColumn
              captions={["episodesStatistics.normalBreathEpisodesCount"]}
              stats={[normalBreathEpisodesCount]}
            />
          </Grid>
          <Grid item xs={3}>
            <ClebreDetailsColumn
              captions={["episodesStatistics.snoreEpisodesCount"]}
              stats={[snoreEpisodesCount]}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={3}>
            <ClebreDetailsColumn
              className={this.props.classes.bottomMargin}
              captions={["episodesStatistics.meanRespiratoryRate"]}
              stats={[meanRespiratoryRate]}
            />
          </Grid>
          <Grid item xs={3}>
            <ClebreDetailsColumn
              className={this.props.classes.bottomMargin}
              captions={["episodesStatistics.respiratoryRateVariability"]}
              stats={[respiratoryRateVariability]}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={3}>
            <ClebreDetailsColumn
              captions={["episodesStatistics.snoringIndex"]}
              stats={[`${renderWithNarrowedDecimalPoints(snoringIndex * 100, 0)} %`]}
            />
          </Grid>
          <Grid item xs={3}>
            <ClebreDetailsColumn
              captions={["episodesStatistics.supineSnoringIndex"]}
              stats={[`${renderWithNarrowedDecimalPoints(supineSnoringIndex * 100, 0)} %`]}
            />
          </Grid>
          <Grid item xs={3}>
            <ClebreDetailsColumn
              captions={["episodesStatistics.nonSupineSnoringIndex"]}
              stats={[`${renderWithNarrowedDecimalPoints(nonSupineSnoringIndex * 100, 0)} %`]}
            />
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </>
    ) : null;
  }

  renderApneaAlgorithmDetails() {
    const {
      hypopneaCount,
      apneaCount,
      ahi,
      supineAhi,
      nonSupineAhi,
    } = this.props.examinationData.sleepScore;
    return this.props.examinationData ? (
      <>
        <Grid container className={this.props.classes.examinationDetailCenter}>
          <Grid item xs={3}>
            <ClebreDetailsImage
              image={"images/apnea-icon.svg"}
              firstLabel={"segmentationAlgorithm.apnea"}
            />
          </Grid>
          <Grid item xs={3}>
            <ClebreDetailsColumn
              captions={["episodesStatistics.hypopneaCount"]}
              stats={[hypopneaCount]}
            />
          </Grid>
          <Grid item xs={3}>
            <ClebreDetailsColumn
              captions={["episodesStatistics.apneaCount"]}
              stats={[apneaCount]}
            />
          </Grid>
          <Grid item xs={3} />
          <Grid item xs={3} />
          <Grid item xs={3}>
            <ClebreDetailsColumn
              captions={["episodesStatistics.ahi"]}
              stats={[renderWithNarrowedDecimalPoints(ahi, 2)]}
            />
          </Grid>
          <Grid item xs={3}>
            <ClebreDetailsColumn
              captions={["episodesStatistics.supineAhi"]}
              stats={[renderWithNarrowedDecimalPoints(supineAhi, 2)]}
            />
          </Grid>
          <Grid item xs={3}>
            <ClebreDetailsColumn
              captions={["episodesStatistics.nonSupineAhi"]}
              stats={[renderWithNarrowedDecimalPoints(nonSupineAhi, 2)]}
            />
          </Grid>
        </Grid>
      </>
    ) : null;
  }

  renderSleepAlgorithmDetails() {
    const {
      totalSleepTime,
      totalWakeTime,
      totalRegistrationTime,
      sleepEfficiency,
      sleepScore,
    } = this.props.examinationData.sleepScore;
    return this.props.examinationData ? (
      <Grid container className={this.props.classes.examinationDetailCenter}>
        <Grid item xs={3}>
          <ClebreDetailsImage
            image={"images/sleep-icon.svg"}
            firstLabel={"segmentationAlgorithm.sleep"}
          />
        </Grid>
        <Grid item xs={3}>
          <ClebreDetailsColumn
            captions={["episodesStatistics.totalSleepTime"]}
            stats={[formatDurationInSeconds(totalSleepTime)]}
          />
        </Grid>
        <Grid item xs={3}>
          <ClebreDetailsColumn
            captions={["episodesStatistics.totalWakeTime"]}
            stats={[formatDurationInSeconds(totalWakeTime)]}
          />
        </Grid>
        <Grid item xs={3}>
          <ClebreDetailsColumn
            captions={["episodesStatistics.totalRegistrationTime"]}
            stats={[formatDurationInSeconds(totalRegistrationTime)]}
          />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={3}>
          <ClebreDetailsColumn
            captions={["episodesStatistics.sleepEfficiency"]}
            stats={[`${renderWithNarrowedDecimalPoints(sleepEfficiency * 100, 0)} %`]}
          />
        </Grid>
        <Grid item xs={3}>
          <ClebreDetailsColumn captions={["examination.sleepScore"]} stats={[sleepScore]} />
        </Grid>
      </Grid>
    ) : null;
  }

  renderBodyPositionAlgorithmDetails() {
    const {
      uprightPositionRatio,
      supinePositionRatio,
      nonSupinePositionRatio,
    } = this.props.examinationData.sleepScore;
    return this.props.examinationData ? (
      <Grid container className={this.props.classes.examinationDetailCenter}>
        <Grid item xs={3}>
          <ClebreDetailsImage
            image={"images/body-position-icon.svg"}
            firstLabel={"segmentationAlgorithm.bodyPosition"}
          />
        </Grid>
        <Grid item xs={3}>
          <ClebreDetailsColumn
            captions={["episodesStatistics.uprightPositionRatio"]}
            stats={[`${renderWithNarrowedDecimalPoints(uprightPositionRatio * 100, 0)} %`]}
          />
        </Grid>
        <Grid item xs={3}>
          <ClebreDetailsColumn
            captions={["episodesStatistics.supinePositionRatio"]}
            stats={[`${renderWithNarrowedDecimalPoints(supinePositionRatio * 100, 0)} %`]}
          />
        </Grid>
        <Grid item xs={3}>
          <ClebreDetailsColumn
            captions={["episodesStatistics.nonSupinePositionRatio"]}
            stats={[`${renderWithNarrowedDecimalPoints(nonSupinePositionRatio * 100, 0)} %`]}
          />
        </Grid>
      </Grid>
    ) : null;
  }

  renderSegmentationDetails(type) {
    switch (type) {
      case "SLEEP":
        return this.renderSleepAlgorithmDetails();
      case "BODY_POSITION":
        return this.renderBodyPositionAlgorithmDetails();
      case "BREATH":
        return this.renderBreathAlgorithmDetails();
      case "APNEA":
        return this.renderApneaAlgorithmDetails();
      default:
        return null;
    }
  }

  checkIfEachEpisodesAreEmpty() {
    return this.state.episodesToVisualize
      ? _.every(
          _.values(this.state.episodesToVisualize).map(
            (episodesToVisualize) => episodesToVisualize.episodes
          ),
          (value) => _.isEmpty(value)
        )
      : true;
  }

  render() {
    return (
      <Grid container spacing={3}>
        {_.isEmpty(this.props.examinationData) ? (
          <Typography variant="h5">
            {translateValue(this.props.intl, "episodesStatistics.noSegmentationData")}
          </Typography>
        ) : (
          SegmentationResults.EPISODE_TYPES_SEGMENTATION_ORDER.map((type) => (
            <Grid key={type} item xs={12}>
              <Paper key={type}>{this.renderSegmentationDetails(type)}</Paper>
            </Grid>
          ))
        )}
      </Grid>
    );
  }
}

const styles = (theme) => ({
  examinationDetailCenter: {
    textAlign: "center",
    padding: theme.spacing(4),
  },
  bottomMargin: {
    marginBottom: theme.spacing(8),
  },
});

export default withStyles(styles)(injectIntl(connectComponent(SegmentationResults)));
