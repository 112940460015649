export const permissions = {
  USER_LIST: "USER_LIST",
  USER_EDIT_GLOBAL: "USER_EDIT_GLOBAL",
  USER_EDIT: "USER_EDIT",
  USER_GET_GLOBAL: "USER_GET_GLOBAL",
  USER_GET: "USER_GET",
  PROFILE_ADD: "PROFILE_ADD",
  PROFILE_EDIT_GLOBAL: "PROFILE_EDIT_GLOBAL",
  PROFILE_EDIT: "PROFILE_EDIT",
  PROFILE_LIST: "PROFILE_LIST",
  PROFILE_LIST_GLOBAL: "PROFILE_LIST_GLOBAL",
  PROFILE_GET: "PROFILE_GET",
  PROFILE_GET_GLOBAL: "PROFILE_GET_GLOBAL",
  FILE_UPLOAD_GLOBAL: "FILE_UPLOAD_GLOBAL",
  FILE_UPLOAD: "FILE_UPLOAD",
  FILE_DELETE_GLOBAL: "FILE_DELETE_GLOBAL",
  FILE_LIST_GLOBAL: "FILE_LIST_GLOBAL",
  FILE_LIST: "FILE_LIST",
  FILE_DOWNLOAD_GLOBAL: "FILE_DOWNLOAD_GLOBAL",
  FILE_DOWNLOAD: "FILE_DOWNLOAD",
  SENSOR_ADD: "SENSOR_ADD",
  SENSOR_EDIT: "SENSOR_EDIT",
  SENSOR_REMOVE_DAMAGE_GLOBAL: "SENSOR_REMOVE_DAMAGE_GLOBAL",
  SENSOR_REMOVE_DAMAGE: "SENSOR_REMOVE_DAMAGE",
  SENSOR_LIST: "SENSOR_LIST",
  SENSOR_LIST_GLOBAL: "SENSOR_LIST_GLOBAL",
  SENSOR_GET_GLOBAL: "SENSOR_GET_GLOBAL",
  SENSOR_GET: "SENSOR_GET",
  GET_ACCESS_TOKEN: "GET_ACCESS_TOKEN",
  SENSOR_LEND_GLOBAL: "SENSOR_LEND_GLOBAL",
  SENSOR_LEND: "SENSOR_LEND",
  SENSOR_LEND_LIST_GLOBAL: "SENSOR_LEND_LIST_GLOBAL",
  SENSOR_LEND_LIST: "SENSOR_LEND_LIST",
  SENSOR_OWNERSHIP_CREATE_GLOBAL: "SENSOR_OWNERSHIP_CREATE_GLOBAL",
  SENSOR_OWNERSHIP_CREATE: "SENSOR_OWNERSHIP_CREATE",
  SENSOR_OWNERSHIP_LIST_GLOBAL: "SENSOR_OWNERSHIP_LIST_GLOBAL",
  SENSOR_OWNERSHIP_LIST: "SENSOR_OWNERSHIP_LIST",
  SENSOR_OWNERSHIP_REMOVE_GLOBAL: "SENSOR_OWNERSHIP_REMOVE_GLOBAL",
  SENSOR_OWNERSHIP_REMOVE: "SENSOR_OWNERSHIP_REMOVE",
  FIRMWARE_ADD: "FIRMWARE_ADD",
  FIRMWARE_PACKAGE_GET: "FIRMWARE_PACKAGE_GET",
  FIRMWARE_LIST: "FIRMWARE_LIST",
  FIRMWARE_EDIT: "FIRMWARE_EDIT",
  FIRMWARE_DOWNLOAD: "FIRMWARE_DOWNLOAD",
  EXAMINATION_ADD_GLOBAL: "EXAMINATION_ADD_GLOBAL",
  EXAMINATION_ADD: "EXAMINATION_ADD",
  EXAMINATION_LIST: "EXAMINATION_LIST",
  EXAMINATION_LIST_GLOBAL: "EXAMINATION_LIST_GLOBAL",
  EXAMINATION_DELETE: "EXAMINATION_DELETE",
  EXAMINATION_DELETE_GLOBAL: "EXAMINATION_DELETE_GLOBAL",
  GENERATE_DEMO_DEV: "GENERATE_DEMO_DEV",
  EXPERT_CLASSIFICATION_START: "EXPERT_CLASSIFICATION_START",
  EXPERT_CLASSIFICATION_FINISH: "EXPERT_CLASSIFICATION_FINISH",
  EXPERT_CLASSIFICATION_GET_GLOBAL: "EXPERT_CLASSIFICATION_GET_GLOBAL",
  EXPERT_CLASSIFICATION_GET: "EXPERT_CLASSIFICATION_GET",
  EXPERT_CLASSIFICATION_LIST_GLOBAL: "EXPERT_CLASSIFICATION_LIST_GLOBAL",
  EXPERT_CLASSIFICATION_LIST: "EXPERT_CLASSIFICATION_LIST",
  EXAMINATION_GET_GLOBAL: "EXAMINATION_GET_GLOBAL",
  EXAMINATION_GET: "EXAMINATION_GET",
  EXAMINATION_CLASSIFY: "EXAMINATION_CLASSIFY",
  GROUP_MANAGER: "GROUP_MANAGER",
  CHANGE_CONTEXT: "CHANGE_CONTEXT",
  PROFILE_ADD_VALIDATED: "PROFILE_ADD_VALIDATED",
};

export const hasPermissions = (requiredPermissions, user) => {
  // validates if user has ANY of permissions given

  const userPermissions = new Set(user.currentGroup.permissions);

  for (let permission of requiredPermissions) {
    if (userPermissions.has(permission)) {
      return true;
    }
  }
  return false;
};
