import React, { Component } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import clsx from "clsx";
import { injectIntl } from "react-intl";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import { TabViewEnum } from "../TabViewEnum";
import { withStyles } from "@material-ui/core/styles";
import { connectComponent } from "../actions/ActionUtils";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Grid from "@material-ui/core/Grid";
import { ToolbarIcon } from "../component/ToolbarIcon/ToolbarIcon";
import { translateValue } from "../i18n/ClebreTranslator";

class MainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      anchorEl: null,
      mobileMoreAnchorEl: null,
    };
  }

  handleProfileMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  logOutUser = () => {
    this.handleMenuClose();
    this.props.actions.userLoggedOut();
    window.history.pushState("", "", "/");
  };

  handleTabChange = (event, newTopMenuTabValue) => {
    if (newTopMenuTabValue !== 0) {
      this.setActiveTab(newTopMenuTabValue);
    } else {
      this.setActiveTab(this.defaultActiveTab());
    }
  };

  renderLogo = () => {
    return (
      <div className={this.props.classes.toolbar}>
        <ToolbarIcon />
      </div>
    );
  };

  shouldHideTab = (tabView) => {
    const viewPrivilege = {
      [TabViewEnum.EXAMINATIONS]: ["ADMIN", "DOCTOR", "BASIC", "RESEARCHER"],
      [TabViewEnum.PROFILES]: ["ADMIN", "DOCTOR", "BASIC", "RESEARCHER"],
      [TabViewEnum.CLASSIFICATION]: ["ADMIN", "RESEARCHER"],
      [TabViewEnum.USERS]: ["ADMIN"],
      [TabViewEnum.SENSORS]: ["ADMIN", "DOCTOR"],
      [TabViewEnum.FIRMWARE]: ["ADMIN"],
    };
    return !viewPrivilege[tabView].includes(this.props.user.currentGroup.name);
  };

  getActiveTab = () => {
    const activeTab = localStorage.getItem("menuActiveTab");
    if (activeTab) {
      return parseInt(activeTab);
    } else {
      return this.defaultActiveTab();
    }
  };

  setActiveTab = (activeTab) => {
    localStorage.setItem("menuActiveTab", activeTab);
  };

  defaultActiveTab = () => {
    const defaultActiveTabPerRole = {
      ADMIN: 1,
      DOCTOR: 2,
      BASIC: 1,
    };
    const defaultTab = defaultActiveTabPerRole[this.props.user.currentGroup.name];
    if (defaultTab) {
      return defaultTab;
    } else {
      return 1;
    }
  };

  defaultTabHref = () => {
    const examinationHref = "/#examinations";
    const profileHref = "/#profiles";
    const defaultTabHrefPerRole = {
      ADMIN: examinationHref,
      DOCTOR: profileHref,
      BASIC: examinationHref,
    };
    const defaultHrefForRole = defaultTabHrefPerRole[this.props.user.currentGroup.name];
    if (defaultHrefForRole) {
      return defaultHrefForRole;
    } else {
      return examinationHref;
    }
  };

  render() {
    return (
      <div>
        <AppBar
          position="absolute"
          className={clsx(
            this.props.classes.appBar,
            this.state.open && this.props.classes.appBarShift
          )}
        >
          <Grid container>
            <Grid item xs={10}>
              <Tabs
                value={this.getActiveTab()}
                onChange={this.handleTabChange}
                variant="scrollable"
                scrollButtons="on"
              >
                <Tab
                  value={0}
                  icon={this.renderLogo()}
                  href={this.defaultTabHref()}
                  style={{ opacity: 1 }}
                />
                <Tab
                  value={1}
                  hidden={this.shouldHideTab(TabViewEnum.EXAMINATIONS)}
                  href={"/#examinations"}
                  label={translateValue(this.props.intl, "sidebar.examinations")}
                />
                <Tab
                  value={2}
                  hidden={this.shouldHideTab(TabViewEnum.CLASSIFICATION)}
                  href={"/#classification"}
                  label={translateValue(this.props.intl, "sidebar.classification")}
                />
                <Tab
                  value={3}
                  hidden={this.shouldHideTab(TabViewEnum.PROFILES)}
                  href={"/#profiles"}
                  label={translateValue(this.props.intl, "sidebar.profiles")}
                />
                <Tab
                  value={4}
                  hidden={this.shouldHideTab(TabViewEnum.USERS)}
                  href={"/#users"}
                  label={translateValue(this.props.intl, "sidebar.users")}
                />
                <Tab
                  value={5}
                  hidden={this.shouldHideTab(TabViewEnum.SENSORS)}
                  href={"/#sensors"}
                  label={translateValue(this.props.intl, "sidebar.sensors")}
                />
                <Tab
                  value={6}
                  hidden={this.shouldHideTab(TabViewEnum.FIRMWARE)}
                  href={"/#firmware"}
                  label={translateValue(this.props.intl, "sidebar.firmware")}
                />
              </Tabs>
            </Grid>
            <Grid item xs={2}>
              <Toolbar className={this.props.classes.toolbar}>
                <div className={this.props.classes.grow} />
                <Button
                  className={this.props.classes.i18nButton}
                  color="inherit"
                  onClick={() => {
                    this.props.actions.changeLocale("pl-PL");
                  }}
                >
                  PL
                </Button>
                <Button
                  className={this.props.classes.i18nButton}
                  color="inherit"
                  onClick={() => {
                    this.props.actions.changeLocale("en-US");
                  }}
                >
                  EN
                </Button>
                <div>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={"primary-search-account-menu"}
                    aria-haspopup="true"
                    onClick={this.handleProfileMenuOpen}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                </div>
              </Toolbar>
              <Menu
                anchorEl={this.state.anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                id={"primary-search-account-menu"}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
              >
                <MenuItem component={"a"} href="/#user-profile" onClick={this.handleMenuClose}>
                  {translateValue(this.props.intl, "navbar.myAccount")}
                </MenuItem>
                <MenuItem onClick={this.logOutUser}>
                  {translateValue(this.props.intl, "navbar.logout")}
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </AppBar>
      </div>
    );
  }
}

const styles = (theme) => ({
  toolbar: {
    minHeight: "80px",
    paddingRight: 24,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
  },
  appBarShift: {
    marginLeft: 100,
    marginRight: 100,
    width: `calc(100% - ${2 * 100}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  i18nButton: {
    minWidth: 0,
  },
});

export default withStyles(styles)(injectIntl(connectComponent(MainMenu)));
