import * as Yup from "yup";
import { translateValue } from "../i18n/ClebreTranslator";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

// A custom validation function was needed - Yup doesn't validate properly years before 100 (2-digit)
const validateDateMin = (date) => {
  if (!date) return true;
  const min = new Date(1900, 0, 2);
  return new Date(date) > min;
};

const validateDateMax = (date) => {
  if (!date) return true;
  const max = new Date();
  return new Date(date) < max;
};

const validatePhoneNumber = (phoneNumber) => {
  let valid = true;
  try {
    // some contry codes have 5 digits
    if (phoneNumber && phoneNumber.length > 5) {
      const region = phoneUtil.getRegionCodeForNumber(phoneUtil.parse(phoneNumber));
      const number = phoneUtil.parseAndKeepRawInput(phoneNumber, region);
      valid = phoneUtil.isValidNumberForRegion(number, region);
    }
  } catch (error) {
    valid = false;
  }
  return valid;
};

export const emailValidation = (intl) =>
  Yup.string()
    .email(translateValue(intl, "invalid.email"))
    .required(translateValue(intl, "required.email"));

export const passwordValidation = (intl) =>
  Yup.string()
    .required(translateValue(intl, "required.password"))
    .matches(
      /^(?=.*[0-9])(?=.*[a-z-żźćńółęąś])(?=.*[A-Z-ŻŹĆŃÓŁĘĄŚ])(?=.*[*.!@#$%^&(){}[\]:;'"<>,.?/~`_+\-=|\\]).{8,}$/,
      translateValue(intl, "invalid.password")
    );

export const firstNameValidation = (intl) =>
  Yup.string()
    .required(translateValue(intl, "required.firstName"))
    .min(2, translateValue(intl, "invalid.firstName.length"))
    .max(32, translateValue(intl, "invalid.firstName.length"))
    .matches(/^[^0-9!@#$%^&(){}[\]:;"<>,.?/~`_+=|\\]+$/, translateValue(intl, "invalid.firstName"));

export const lastNameValidation = (intl) =>
  Yup.string()
    .required(translateValue(intl, "required.lastName"))
    .min(2, translateValue(intl, "invalid.lastName.length"))
    .max(32, translateValue(intl, "invalid.lastName.length"))
    .matches(/^[^0-9!@#$%^&(){}[\]:;"<>,.?/~`_+=|\\]+$/, translateValue(intl, "invalid.lastName"));

export const dateOfBirthValidation = (intl) =>
  Yup.string()
    .test("Check dateOfBirth min", translateValue(intl, "invalid.dateOfBirth.min"), validateDateMin)
    .test("Check dateOfBirth max", translateValue(intl, "invalid.dateOfBirth.max"), validateDateMax)
    .required(translateValue(intl, "required.dateOfBirth"));

export const genderValidation = (intl) =>
  Yup.string().required(translateValue(intl, "required.gender"));

export const heightValidation = (intl) =>
  Yup.number()
    .required(translateValue(intl, "required.height"))
    .min(0, translateValue(intl, "invalid.height"))
    .max(250, translateValue(intl, "invalid.height"));

export const weightValidation = (intl) =>
  Yup.number()
    .required(translateValue(intl, "required.weight"))
    .min(0, translateValue(intl, "invalid.weight"))
    .max(200, translateValue(intl, "invalid.weight"));

export const phoneNumberValidation = (intl) =>
  Yup.string()
    .nullable()
    .test("Check phoneNumber", translateValue(intl, "invalid.phoneNumber"), validatePhoneNumber);
