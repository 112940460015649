import React, { Component } from "react";
import { HttpService } from "../http-service/HttpService";
import { connectComponent } from "../actions/ActionUtils";
import UserRolesEdit from "./UserRolesEdit";
import { injectIntl } from "react-intl";
import { translateValue } from "../i18n/ClebreTranslator";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import ClebreTableHeader from "../component/ClebreTable/ClebreTableHeader";
import TablePagination from "@material-ui/core/TablePagination";
import { hasPermissions, permissions } from "../util/validatePermissions";
import { CircularOnBackdrop } from "../component/Progress/CircularOnBackdrop";
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      roles: [],
      limit: 25,
      page: 0,
      totalRecords: 0,
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchUsers();
  }

  setLoading = (value) => {
    this.setState({ loading: value });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => this.fetchUsers());
  };

  handleRowsPerPage = (event) => {
    this.setState({ limit: event.target.value }, () => this.fetchUsers());
  };

  fetchUsers() {
    this.setLoading(true);
    HttpService.paginateUsers(this.state.limit, this.state.page)
      .then((response) => {
        this.setState({
          users: response.content,
          totalRecords: response.totalElements,
          loading: false,
        });
      })
      .catch(() => {
        this.setLoading(false);
      });
  }

  headCells = [
    { id: "email", align: "left", label: "Email" },
    { id: "ID", align: "left", label: "ID" },
    {
      id: "isActive",
      align: "left",
      label: translateValue(this.props.intl, "user.isActive"),
    },
    {
      id: "firstName",
      align: "left",
      label: translateValue(this.props.intl, "firstName"),
    },
    {
      id: "lastName",
      align: "left",
      label: translateValue(this.props.intl, "lastName"),
    },
    {
      id: "userRole",
      align: "left",
      label: translateValue(this.props.intl, "user.groups"),
    },
  ];

  render() {
    return (
      <div className="allUsers">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <ClebreTableHeader headCells={this.headCells} />
            <TableBody>
              {this.state.users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell component="th">{user.email}</TableCell>
                  <TableCell component="th" scope="row">
                    {user.id}
                  </TableCell>
                  <TableCell>
                    {user.active
                      ? translateValue(this.props.intl, "yes")
                      : translateValue(this.props.intl, "no")}
                  </TableCell>
                  <TableCell>{user.firstName}</TableCell>
                  <TableCell>{user.lastName}</TableCell>
                  <TableCell className={this.props.classes.group}>
                    <div>
                      <UserRolesEdit
                        selectedUser={user}
                        hasPermission={hasPermissions(
                          [permissions.USER_EDIT_GLOBAL],
                          this.props.user
                        )}
                        setLoading={this.setLoading}
                      />
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {this.state.totalRecords === 0 && !this.state.loading && (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    {translateValue(this.props.intl, "noDataFound")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          labelRowsPerPage={translateValue(this.props.intl, "table.rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) => {
            return ` ${from} - ${to} ${translateValue(this.props.intl, "of")} ${count}`;
          }}
          page={this.state.page}
          rowsPerPage={this.state.limit}
          count={this.state.totalRecords}
          onChangePage={(event, newPage) => this.handleChangePage(event, newPage)}
          onChangeRowsPerPage={(event) => this.handleRowsPerPage(event)}
        />
        {this.state.loading && <CircularOnBackdrop />}
      </div>
    );
  }
}

const styles = (theme) => ({
  group: {
    maxWidth: "15vw",
  },
});

export default withStyles(styles)(injectIntl(connectComponent(Users)));
