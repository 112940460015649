import React, { Component } from "react";
import { HttpService } from "../http-service/HttpService";
import { connectComponent } from "../actions/ActionUtils";
import _ from "lodash";
import AddSensor from "./AddSensor";
import { injectIntl } from "react-intl";
import DateTimeValue from "../component/date-time-value/DateTimeValue";
import { translateValue } from "../i18n/ClebreTranslator";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Create from "@material-ui/icons/Create";
import ClebreTableHeader from "../component/ClebreTable/ClebreTableHeader";
import SensorEdit from "./SensorEdit";
import { convertTimezone } from "../util/timezoneConverter";
import TablePagination from "@material-ui/core/TablePagination";
import { CircularOnBackdrop } from "../component/Progress/CircularOnBackdrop";
class Sensors extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sensors: [],
      limit: 10,
      totalRecords: 0,
      page: 0,
      loading: false,
    };
  }

  componentDidMount() {
    this.fetchSensors();
  }

  fetchSensors() {
    this.setState({ loading: true });
    HttpService.paginateSensors(this.state.limit, this.state.page)
      .then((response) => {
        this.setState({
          sensors: response.content,
          totalRecords: response.totalElements,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => this.fetchSensors());
  };

  handleRowsPerPage = (event) => {
    this.setState({ limit: event.target.value }, () => this.fetchSensors());
  };

  setSensorToEdit = (sensor) => {
    this.props.actions.showEditSensorModal(sensor);
  };

  renderSensorTable() {
    const headCells = [
      {
        id: "sensorSerialNumber",
        align: "left",
        label: translateValue(this.props.intl, "sensor.serialNumber"),
      },
      { id: "createdAt", label: translateValue(this.props.intl, "createdAt") },
      {
        id: "sensorSensorStatus",
        label: translateValue(this.props.intl, "sensor.sensorStatus"),
      },
      {
        id: "sensorLabel",
        label: translateValue(this.props.intl, "sensor.label"),
      },
      {
        id: "sensorFirmwareVersion",
        label: translateValue(this.props.intl, "sensor.firmwareVersion"),
      },
      {
        id: "sensorHardwareRevision",
        label: translateValue(this.props.intl, "sensor.hardwareRevision"),
      },
      { id: "edit", label: translateValue(this.props.intl, "button.edit") },
    ];
    return (
      <>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <ClebreTableHeader headCells={headCells} />
            <TableBody>
              {this.state.sensors.map((sensor) => (
                <TableRow key={sensor.serialNumber}>
                  <TableCell component="th" scope="row">
                    {sensor.serialNumber}
                  </TableCell>
                  <TableCell>
                    <DateTimeValue
                      datetime={convertTimezone(sensor.createdDate, this.props.timezone)}
                      formatDate={"YYYY-MM-DD"}
                    />
                  </TableCell>
                  <TableCell>
                    {translateValue(this.props.intl, `sensor.sensorStatus.${sensor.sensorStatus}`)}
                  </TableCell>
                  <TableCell>{sensor.customLabel}</TableCell>
                  <TableCell>{sensor.firmwareVersion}</TableCell>
                  <TableCell>{sensor.hardwareRevision}</TableCell>
                  <TableCell>
                    <div>
                      <IconButton
                        color="primary"
                        aria-label="edit sensor"
                        onClick={() => this.setSensorToEdit(sensor)}
                      >
                        <Create />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
              {this.state.totalRecords === 0 && !this.state.loading && (
                <TableRow>
                  <TableCell align="center" colSpan={7}>
                    {translateValue(this.props.intl, "noDataFound")}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          labelRowsPerPage={translateValue(this.props.intl, "table.rowsPerPage")}
          labelDisplayedRows={({ from, to, count }) => {
            return ` ${from} - ${to} ${translateValue(this.props.intl, "of")} ${count}`;
          }}
          page={this.state.page}
          rowsPerPage={this.state.limit}
          count={this.state.totalRecords}
          onChangePage={(event, newPage) => this.handleChangePage(event, newPage)}
          onChangeRowsPerPage={(event) => this.handleRowsPerPage(event)}
        />
        {this.state.loading && <CircularOnBackdrop />}
      </>
    );
  }

  render() {
    return (
      <div className={"sensors"}>
        {this.addSensor()}
        {this.renderSensorTable()}
        {this.editSensor()}
      </div>
    );
  }

  addSensor() {
    return <AddSensor loadSensors={() => this.fetchSensors()} />;
  }

  editSensor() {
    return !_.isEmpty(this.props.sensorToEdit) ? (
      <SensorEdit loadSensors={() => this.fetchSensors()} />
    ) : null;
  }
}

export default injectIntl(connectComponent(Sensors));
