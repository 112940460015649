import React, { Component } from "react";
import { v4 as uuidv4 } from "uuid";
import { HttpService } from "../http-service/HttpService";
import { connectComponent } from "../actions/ActionUtils";
import { injectIntl } from "react-intl";
import ClebreFormModal from "../component/clebre-form/ClebreFormModal";
import { UploadButton } from "../component/Button/UploadButton";
import FormControl from "@material-ui/core/FormControl";
import { FormInputBase } from "../component/input/FormInputBase";
import { translateValue } from "../i18n/ClebreTranslator";

class AddFirmware extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hardwareRevision: "",
      version: "",
      changelog: "",
      showAddFirmwareModal: false,
      package: "",
      packageName: "",
    };
  }

  addFile(event) {
    const file = event.target.files[0];
    this.setState({ package: file, packageName: file.name });
  }

  saveFirmware = () => {
    let packageId = uuidv4();

    HttpService.saveFirmwarePackage(this.state.package, packageId)
      .then((data) => {
        return HttpService.saveFirmwareMetadata(
          {
            changelog: this.state.changelog,
            packageId: packageId,
          },
          this.state.hardwareRevision,
          this.state.version
        );
      })
      .then((data) => {
        this.handleAddModalClose();
        this.props.loadFirmware();
      });
  };

  handleAddModalShow() {
    this.setState({ showAddFirmwareModal: true });
  }

  handleAddModalClose() {
    this.setState({ showAddFirmwareModal: false });
  }

  renderForm() {
    return (
      <div className={"addFirmwareForm"}>
        <div>
          <FormInputBase
            required
            label={translateValue(this.props.intl, "firmware.hardwareRevision")}
            value={this.state.hardwareRevision ? this.state.hardwareRevision : ""}
            onChange={(event) => this.setState({ hardwareRevision: event.target.value })}
            helperText={translateValue(this.props.intl, "firmware.helper.hardwareRevision")}
          />
        </div>
        <div>
          <FormInputBase
            required
            label={translateValue(this.props.intl, "firmware.version")}
            value={this.state.version ? this.state.version : ""}
            onChange={(event) => this.setState({ version: event.target.value })}
            helperText={translateValue(this.props.intl, "firmware.helper.version")}
          />
        </div>
        <div>
          <FormInputBase
            multiline
            label={translateValue(this.props.intl, "firmware.changelog")}
            value={this.state.changelog ? this.state.changelog : ""}
            onChange={(event) => this.setState({ changelog: event.target.value })}
          />
        </div>
        <div>
          <FormControl>
            <UploadButton>
              {translateValue(this.props.intl, "file.firmwareBinary")}
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(event) => this.addFile(event)}
              />
            </UploadButton>
            {this.state.packageName ? <label>{this.state.packageName}</label> : null}
          </FormControl>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className={"firmwareModal"}>
        <UploadButton onClick={() => this.handleAddModalShow()}>
          {translateValue(this.props.intl, "button.addFirmware")}
        </UploadButton>

        <ClebreFormModal
          i18nLabelTitle="modal.headingAddFirmware"
          modalClass="addFirmware"
          formBody={this.renderForm()}
          onSave={(event) => this.saveFirmware(event)}
          onHide={() => this.handleAddModalClose()}
          show={this.state.showAddFirmwareModal}
        />
      </div>
    );
  }
}

export default injectIntl(connectComponent(AddFirmware));
