import JSZip from "jszip";
import moment from "moment";
import { HttpService } from "../../../http-service/HttpService";

export const sendExaminationPackage = (
  requiredFiles,
  examinationId,
  profileId,
  setUploadStatus
) => {
  const base64examinationId = btoa(examinationId);

  const jsnFilePromise = getExaminationData(requiredFiles.JSN.file);

  const zipFilePromise = createZip(requiredFiles, base64examinationId);

  return Promise.all([jsnFilePromise, zipFilePromise])
    .then(([jsnFile, zipFile]) => {
      return HttpService.uploadExamination(
        base64examinationId,
        zipFile,
        jsnFile,
        profileId,
        setUploadStatus
      ).catch((error) => {
        throw error;
      });
    })
    .catch((error) => {
      throw error;
    });
};

const createZip = (requiredFiles, examinationId) => {
  const zip = new JSZip();

  for (let fileType in requiredFiles) {
    const base64name = `${examinationId}.${fileType}`;
    zip.file(base64name, requiredFiles[fileType].file);
  }

  return zip.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: {
      level: 1,
    },
  });
};

const getExaminationData = (jsnFile) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = (event) => {
      const jsnContent = JSON.parse(event.target.result);
      const examinationData = {};

      const timezone = moment.tz.guess();

      const examinationStart = new Date(jsnContent.examination_start_timestamp * 1000);
      const examinationEnd = new Date(jsnContent.examination_stop_timestamp * 1000);

      const formattedStart = moment.tz(examinationStart, timezone).format();
      const formattedEnd = moment.tz(examinationEnd, timezone).format();

      examinationData["sensorNumber"] = jsnContent.sensor_serial;
      examinationData["recordId"] = btoa(jsnContent.examination_id);
      examinationData["examinationStart"] = formattedStart;
      examinationData["examinationEnd"] = formattedEnd;
      examinationData["firmwareVersion"] = jsnContent.firmware_version;
      examinationData["hardwareVersion"] = jsnContent.hardware_revision;

      const examinationDataJson = JSON.stringify(examinationData);
      const examinationDataBlob = new Blob([examinationDataJson], {
        type: "application/json",
      });

      resolve(examinationDataBlob);
    };
    reader.readAsText(jsnFile);
  });
};
