export const acceptedFileExtensions = [".acc", ".env", ".jsn", ".log", ".par", ".pox"];

// in bytes
export const maxFileSize = 50000000;

export const fileNumberLimit = 1000;

export const examinationStatuses = {
  PENDING: "PENDING",
  IN_PROGRESS: "IN_PROGRESS",
  DONE: "DONE",
  FAILED: "FAILED",
};
