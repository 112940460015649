import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export const ToolbarIcon = () => {
  const classes = useStyles();

  return (
    <div className={classes.toolbarIcon}>
      <img src="images/logo_horiz.png" alt="logo" />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    ...theme.mixins.toolbar,
  },
}));
