import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

export const FormInputBase = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <TextField {...props} className={classes.formInput}>
      {children}
    </TextField>
  );
};

const useStyles = makeStyles((theme) => ({
  formInput: {
    margin: theme.spacing(1),
    width: "25ch",
  },
}));
