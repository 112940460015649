import React, { Component } from "react";
import { connectComponent } from "../../actions/ActionUtils";
import moment from "moment-timezone";

class DateTimeValue extends Component {
  isEmpty(object) {
    return !(object !== undefined && object !== null && object !== "");
  }

  getDateTimeString() {
    if (this.isEmpty(this.props.datetime)) return "";

    const dt = moment(
      typeof this.props.datetime === "number" ? this.props.datetime : this.props.datetime.toString()
    );

    const tz = !this.isEmpty(this.props.timezone) ? this.props.timezone : moment.tz.guess();

    return dt.tz(tz).format(this.props.formatDate ? this.props.formatDate : "YYYY-MM-DD HH:mm:ss");
  }

  render() {
    return <span>{this.getDateTimeString()}</span>;
  }
}

export default connectComponent(DateTimeValue);
