import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";

class ClebreTableHeader extends Component {
  render() {
    return (
      <TableHead>
        <TableRow>
          {this.props.headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.align ? headCell.align : "left"}
              className={this.props.classes.tableHeadCell}
            >
              {headCell.isSortable ? (
                <TableSortLabel
                  active={headCell.activeSorting ? headCell.activeSorting : false}
                  direction={headCell.sortingDirection ? headCell.sortingDirection : "asc"}
                  onClick={() => headCell.handleSorting()}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

const styles = (theme) => ({
  tableHeadCell: {
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.light,
  },
});

export default withStyles(styles)(ClebreTableHeader);
