import * as Yup from "yup";
import { translateValue } from "../i18n/ClebreTranslator";
import {
  firstNameValidation,
  lastNameValidation,
  dateOfBirthValidation,
  genderValidation,
  heightValidation,
  weightValidation,
  phoneNumberValidation,
} from "../validation/validationConstants";

export const profileValidationSchema = (user, intl, govIdValidationCache) => {
  const validatePesel = (pesel) => {
    // PESEL is optional
    if (!pesel) {
      return true;
    }
    // Every validated PESEL is added to govIdValidationCache with validation result
    if (pesel in govIdValidationCache) {
      return govIdValidationCache[pesel];
    }
    return false;
  };

  const basicValidationSchema = Yup.object().shape({
    firstName: firstNameValidation(intl),
    lastName: lastNameValidation(intl),
    govIdentification: Yup.string()
      .length(11, translateValue(intl, "invalid.pesel.length"))
      .test("Check govid", translateValue(intl, "invalid.pesel"), validatePesel)
      .nullable(),
    dateOfBirth: dateOfBirthValidation(intl),
    gender: genderValidation(intl),
    height: heightValidation(intl),
    weight: weightValidation(intl),
    phoneNumber: phoneNumberValidation(intl),
  });

  const doctorValidationSchema = Yup.object().shape({
    firstName: firstNameValidation(intl),
    lastName: lastNameValidation(intl),
    govIdentification: Yup.string()
      .required(translateValue(intl, "required.govIdentification"))
      .length(11, translateValue(intl, "invalid.pesel.length"))
      .test("Check govid", translateValue(intl, "invalid.pesel"), validatePesel)
      .nullable(),
    dateOfBirth: dateOfBirthValidation(intl),
    gender: genderValidation(intl),
    height: heightValidation(intl),
    weight: weightValidation(intl),
    phoneNumber: phoneNumberValidation(intl),
  });

  const validationSchema =
    user.currentGroup.name === "DOCTOR" ? doctorValidationSchema : basicValidationSchema;

  return validationSchema;
};
