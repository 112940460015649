import React from "react";
import { injectIntl } from "react-intl";
import { connectComponent } from "../actions/ActionUtils";
import { translateValue } from "../i18n/ClebreTranslator";
import { HttpService } from "../http-service/HttpService";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import MuiPhoneNumber from "material-ui-phone-number";
import DateInput from "../component/input/DateInput";
import { weightHeightHandleChange } from "../util/customHandleChange";
import { getUserCountry } from "../util/getUserCountry";

const ProfileForm = (props) => {
  const { govIdValidationCache, intl, user, type } = props;
  const {
    values,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    initialValues,
  } = props.formik;
  const classes = useStyles();
  const userCountry = getUserCountry();

  const genders = [
    { value: "MALE", label: translateValue(intl, "male") },
    { value: "FEMALE", label: translateValue(intl, "female") },
    { value: "OTHER", label: translateValue(intl, "other") },
  ];

  const handleGovidChange = (event) => {
    event.target.value = event.target.value.replace(/[^0-9]+/g, "");
    handleChange(event);
    if (event.target.value.length === 11) {
      govIdValidationCache[event.target.value] = true;
      HttpService.validatePesel(event.target.value).then((data) => {
        govIdValidationCache[event.target.value] = data.result;
        setFieldTouched("govIdentification");
        if (data.result === true) {
          setFieldValue("dateOfBirth", data.dateOfBirth);
          setFieldValue("gender", data.gender);
          setFieldTouched("dateOfBirth");
        } else {
          setFieldValue("dateOfBirth", "");
          setFieldValue("gender", "");
        }
      });
    }
  };

  const handlePhoneNumberChange = (event) => {
    setFieldValue("phoneNumber", event);
  };

  const handleBlurAndTrim = (event) => {
    event.target.value = event.target.value.trim();
    handleBlur(event);
    handleChange(event);
  };

  return (
    <form className={classes.profileForm}>
      <div className={classes.inputColumn}>
        <TextField
          required
          name="firstName"
          label={translateValue(intl, "firstName")}
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlurAndTrim}
          fullWidth
          margin={type === "firstAdd" ? "normal" : "dense"}
          autoComplete="off"
          error={errors.firstName && touched.firstName}
          helperText={errors.firstName && touched.firstName ? errors.firstName : null}
          inputProps={{ style: { textTransform: "capitalize" } }}
        />
        <TextField
          required={user.currentGroup.name === "DOCTOR"}
          name="govIdentification"
          label={translateValue(intl, "govid")}
          value={values.govIdentification}
          disabled={type === "edit" && initialValues.govIdentification}
          onChange={handleGovidChange}
          onBlur={handleBlur}
          fullWidth
          margin={type === "firstAdd" ? "normal" : "dense"}
          className={classes.numberInput}
          autoComplete="off"
          error={errors.govIdentification && touched.govIdentification}
          helperText={
            errors.govIdentification && touched.govIdentification ? errors.govIdentification : null
          }
        />
        <DateInput
          required
          name="dateOfBirth"
          label={translateValue(intl, "birthDate")}
          value={values.dateOfBirth}
          disabled={
            values.govIdentification ? values.govIdentification.toString().length === 11 : false
          }
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          margin={type === "firstAdd" ? "normal" : "dense"}
          autoComplete="off"
          error={errors.dateOfBirth && touched.dateOfBirth}
          helperText={errors.dateOfBirth && touched.dateOfBirth ? errors.dateOfBirth : null}
          InputProps={{
            inputProps: {
              min: "1900-01-01",
              max: new Date().toISOString().split("T")[0],
            },
          }}
        />
        <TextField
          select
          required
          name="gender"
          label={translateValue(intl, "gender")}
          value={values.gender}
          disabled={
            values.govIdentification ? values.govIdentification.toString().length === 11 : false
          }
          onChange={handleChange}
          onBlur={handleBlur}
          fullWidth
          margin={type === "firstAdd" ? "normal" : "dense"}
          autoComplete="off"
          error={errors.gender && touched.gender}
          helperText={errors.gender && touched.gender ? errors.gender : null}
        >
          {genders.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className={classes.inputColumn}>
        <TextField
          required
          name="lastName"
          label={translateValue(intl, "lastName")}
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlurAndTrim}
          fullWidth
          margin={type === "firstAdd" ? "normal" : "dense"}
          autoComplete="off"
          error={errors.lastName && touched.lastName}
          helperText={errors.lastName && touched.lastName ? errors.lastName : null}
          inputProps={{ style: { textTransform: "capitalize" } }}
        />
        <TextField
          required
          name="weight"
          label={translateValue(intl, "weight")}
          value={values.weight}
          onChange={(event) => weightHeightHandleChange(event, handleChange)}
          onBlur={handleBlur}
          fullWidth
          margin={type === "firstAdd" ? "normal" : "dense"}
          className={classes.numberInput}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          autoComplete="off"
          error={errors.weight && touched.weight}
          helperText={errors.weight && touched.weight ? errors.weight : null}
        />
        <TextField
          required
          name="height"
          label={translateValue(intl, "height")}
          value={values.height}
          onChange={(event) => weightHeightHandleChange(event, handleChange)}
          onBlur={handleBlur}
          fullWidth
          margin={type === "firstAdd" ? "normal" : "dense"}
          className={classes.numberInput}
          InputProps={{
            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
          }}
          autoComplete="off"
          error={errors.height && touched.height}
          helperText={errors.height && touched.height ? errors.height : null}
        />
        <MuiPhoneNumber
          defaultCountry={userCountry ? userCountry : "pl"}
          disableAreaCodes={true}
          autoFormat={false}
          maxLength="20"
          name="phoneNumber"
          label={translateValue(intl, "phoneNumber")}
          value={values.phoneNumber}
          onChange={(event) => handlePhoneNumberChange(event)}
          onBlur={handleBlur}
          countryCodeEditable={false}
          fullWidth
          margin={type === "firstAdd" ? "normal" : "dense"}
          autoComplete="off"
          error={errors.phoneNumber && touched.phoneNumber}
          helperText={errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : null}
        />
      </div>
    </form>
  );
};

const useStyles = makeStyles((theme) => ({
  inputColumn: {
    display: "flex",
    flexDirection: "column",
    width: "46%",
  },
  profileForm: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  numberInput: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& [type=number]": {
      "-moz-appearance": "textfield",
    },
  },
}));

export default injectIntl(connectComponent(ProfileForm));
