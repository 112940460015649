import * as Yup from "yup";
import { translateValue } from "../../i18n/ClebreTranslator";
import { passwordValidation, emailValidation } from "../../validation/validationConstants";

export const emailValidationSchema = (intl) => {
  return Yup.object().shape({
    email: emailValidation(intl),
  });
};

export const passwordValidationSchema = (intl, password) => {
  const validatePasswordMatch = (passwordConfirm) => {
    return passwordConfirm === password;
  };

  return Yup.object().shape({
    password: passwordValidation(intl),
    passwordConfirm: Yup.string()
      .required(translateValue(intl, "required.passwordConfirm"))
      .test(
        "Check if password matches",
        translateValue(intl, "invalid.passwordConfirm"),
        validatePasswordMatch
      ),
  });
};
