import React from "react";
import { bindActionCreators } from "redux";
import * as UiActions from "../../actions/Actions";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import CssBaseline from "@material-ui/core/CssBaseline";

const CustomIntlProvider = (props) => {
  return (
    <IntlProvider
      locale={props.locale}
      messages={require(`../../i18n/${props.locale}.json`)}
      defaultLocale={"pl-PL"}
    >
      <CssBaseline />
      {props.children}
    </IntlProvider>
  );
};

function mapState(state) {
  return {
    locale: state.locale,
  };
}

function mapActions(dispatch) {
  return {
    actions: bindActionCreators(UiActions, dispatch),
  };
}

export default connect(mapState, mapActions)(CustomIntlProvider);
