import React from "react";
import clsx from "clsx";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const PaperBase = ({ children, className, ...props }) => {
  const classes = useStyles();

  return (
    <Paper {...props} className={clsx(className, classes.paper)}>
      {children}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
}));

export default PaperBase;
