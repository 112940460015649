import React, { Component } from "react";
import { connectComponent } from "../../actions/ActionUtils";
import { showInfo } from "../../notifications/ClebreNotifications";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { translateValue } from "../../i18n/ClebreTranslator";
import { getDuration } from "../../util/Formatter";

class SessionTimer extends Component {
  static showNotificationTimeout = 20;

  componentDidMount() {
    this.sessionCountDown = this.setSessionCountDown();
  }

  setSessionCountDown() {
    return setInterval(() => {
      const currentDate = new Date();
      const remainingTime = Math.round(getDuration(currentDate, this.props.expirationDate));

      if (remainingTime === SessionTimer.showNotificationTimeout) {
        showInfo(translateValue(this.props.intl, "sessionTimeout.message"), remainingTime);
      }

      if (remainingTime <= 0) {
        this.logOutUser();
      }
    }, 1000);
  }

  logOutUser() {
    this.props.actions.userLoggedOut();
    this.props.history.push("/");
  }

  componentWillUnmount() {
    clearInterval(this.sessionCountDown);
  }

  render() {
    return (
      //Cannot delete render function, because react component requires it.
      <></>
    );
  }
}

export default connectComponent(withRouter(injectIntl(SessionTimer)));
