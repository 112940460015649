import React from "react";
import { injectIntl } from "react-intl";
import { translateValue } from "../../../i18n/ClebreTranslator";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import NoteAddOutlinedIcon from "@material-ui/icons/NoteAddOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ClebreTableHeader from "../../../component/ClebreTable/ClebreTableHeader";
import { generateColumnField } from "../../../component/ClebreTable/generateTableColumn";

const FileStatusTable = ({ validationState, setValidationState, intl }) => {
  const requiredFiles = validationState.requiredFiles;

  const classes = useStyles();

  const deleteFile = (fileType) => {
    const newState = { ...validationState };

    newState.requiredFiles[fileType].valid = false;
    newState.requiredFiles[fileType].message = "file.add";
    newState.requiredFiles[fileType].file = "";
    newState.requiredFiles[fileType].name = "";
    newState.requiredFiles[fileType].status = "add";

    setValidationState(newState);
  };

  const headCellValues = [
    { name: "fileName", label: translateValue(intl, "file.name") },
    { name: "status", label: translateValue(intl, "file.status") },
    {
      name: "description",
      label: translateValue(intl, "file.statusDescription"),
    },
    { name: "actions", label: translateValue(intl, "file.actions") },
  ];

  const headCells = headCellValues.map((column) => {
    return generateColumnField(column.name, column.label, false);
  });

  const statusIcon = (status) => {
    let icon;
    switch (status) {
      case "add":
        icon = <NoteAddOutlinedIcon />;
        break;
      case "success":
        icon = <CheckOutlinedIcon className={classes.successIcon} />;
        break;
      case "error":
        icon = <ErrorOutlineOutlinedIcon className={classes.errorIcon} />;
        break;
      default:
        icon = <NoteAddOutlinedIcon />;
    }
    return icon;
  };

  return (
    <>
      <Table className={classes.table} size="small">
        <ClebreTableHeader headCells={headCells} />
        <TableBody>
          {Object.keys(requiredFiles).map((fileType) => {
            return (
              <CustomizedTableRow
                key={fileType}
                requiredFiles={requiredFiles}
                fileType={fileType}
                statusIcon={statusIcon}
                deleteFile={deleteFile}
                intl={intl}
              ></CustomizedTableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

const CustomizedTableRow = ({ requiredFiles, fileType, statusIcon, deleteFile, intl }) => {
  const classes = useStyles();

  return (
    <TableRow>
      {fileType === "JSN" ? (
        <JsnNameCell requiredFiles={requiredFiles} intl={intl} />
      ) : (
        <FileNameCell requiredFiles={requiredFiles} fileType={fileType} />
      )}
      <TableCell>{statusIcon(requiredFiles[fileType].status)}</TableCell>
      <TableCell
        className={requiredFiles[fileType].status === "error" ? classes.errorMessage : null}
      >
        {translateValue(intl, requiredFiles[fileType].message)}
      </TableCell>
      <TableCell>
        {requiredFiles[fileType].status === "error" && (
          <IconButton onClick={() => deleteFile(fileType)} size="small">
            <DeleteOutlineIcon className={classes.deleteIcon} />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

const FileNameCell = ({ requiredFiles, fileType }) => {
  return (
    <TableCell>
      <DescriptionOutlinedIcon />
      {requiredFiles[fileType].name}.{fileType}
    </TableCell>
  );
};

const JsnNameCell = ({ requiredFiles, intl }) => {
  const classes = useStyles();

  return (
    <TableCell>
      <DescriptionOutlinedIcon />{" "}
      <span className={classes.jsnFile}>{requiredFiles.JSN.name}.JSN</span>
      {requiredFiles.JSN.status === "add" && (
        <span>{translateValue(intl, "examination.mainFile")}</span>
      )}
    </TableCell>
  );
};

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(2),
  },
  successIcon: {
    color: theme.palette.success.dark,
  },
  errorIcon: {
    color: theme.palette.error.dark,
  },
  jsnFile: {
    fontWeight: "bold",
  },
  deleteIcon: {
    color: theme.palette.error.dark,
  },
  errorMessage: {
    color: theme.palette.error.dark,
  },
}));

export default injectIntl(FileStatusTable);
