import * as Yup from "yup";
import { translateValue } from "../../i18n/ClebreTranslator";
import { emailValidation } from "../../validation/validationConstants";

export const loginValidationSchema = (intl) => {
  return Yup.object().shape({
    email: emailValidation(intl),
    password: Yup.string().required(translateValue(intl, "required.password")),
  });
};
