import React, { Component } from "react";
import { connectComponent } from "../actions/ActionUtils";
import { HttpService } from "../http-service/HttpService";
import { injectIntl } from "react-intl";
import { translateValue } from "../i18n/ClebreTranslator";
import { withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Save from "@material-ui/icons/Save";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import { CircularOnBackdrop } from "../component/Progress/CircularOnBackdrop";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: props.user.firstName ? props.user.firstName : "",
      lastName: props.user.lastName ? props.user.lastName : "",
      loading: false,
    };
  }

  editProfile = (event) => {
    const updateUserData = {
      userId: this.props.user.id,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
    };
    this.setState({ loading: true });
    HttpService.updateCurrentUser(updateUserData)
      .then((data) => {
        this.props.actions.userUpdatedAccount(data);
      })
      .then(() => this.setState({ loading: false }))
      .catch(() => this.setState({ loading: false }));
    event.preventDefault();
  };

  userGroups = this.props.user.groups.map((group) => translateValue(this.props.intl, group.name));

  editProfileForm() {
    return (
      <div className={"editProfileForm"}>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <Paper elevation={3}>
              <Grid container direction="column" alignItems="center">
                <TextField
                  className={this.props.classes.formInputUser}
                  label={translateValue(this.props.intl, "email")}
                  value={this.props.user.email}
                  disabled={true}
                />
                <TextField
                  className={this.props.classes.formInputUser}
                  label={translateValue(this.props.intl, "user.groups")}
                  value={this.userGroups}
                  disabled={true}
                />
                <TextField
                  className={this.props.classes.formInputUser}
                  label={translateValue(this.props.intl, "firstName")}
                  placeholder={this.props.user.firstName}
                  value={this.state.firstName}
                  onChange={(event) => {
                    this.setState({ firstName: event.target.value });
                  }}
                />
                <TextField
                  className={this.props.classes.formInputUser}
                  label={translateValue(this.props.intl, "lastName")}
                  placeholder={this.props.user.lastName}
                  value={this.state.lastName}
                  onChange={(event) => {
                    this.setState({ lastName: event.target.value });
                  }}
                />
              </Grid>
              <Grid container justify="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  className={this.props.classes.profileSaveButton}
                  onClick={(event) => this.editProfile(event)}
                  startIcon={<Save />}
                >
                  {translateValue(this.props.intl, "button.save")}
                </Button>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {this.state.loading && <CircularOnBackdrop />}
      </div>
    );
  }

  render() {
    return <div className="user-profile">{this.editProfileForm()}</div>;
  }
}

const styles = (theme) => ({
  formInputUser: {
    margin: theme.spacing(1),
    width: "70%",
  },
  profileSaveButton: {
    backgroundColor: theme.palette.primary.main,
    margin: "10px",
  },
});

export default withStyles(styles)(injectIntl(connectComponent(UserProfile)));
