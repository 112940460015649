import React from "react";
import Button from "@material-ui/core/Button";

const ApplyFilterButton = ({ children, onClick }) => {
  return (
    <Button variant="contained" color="primary" size="small" onClick={onClick}>
      {children}
    </Button>
  );
};

export default ApplyFilterButton;
