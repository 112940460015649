import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const SearchInput = ({ label, ...props }) => {
  const classes = useStyles();

  return (
    <TextField label={label} type="search" variant="outlined" className={classes.searchField} />
  );
};

const useStyles = makeStyles((theme) => ({
  searchField: {
    width: "30%",
    marginRight: theme.spacing(8),
  },
}));

export default SearchInput;
