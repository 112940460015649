import React from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";
import { translateValue } from "../../i18n/ClebreTranslator";
import TextField from "@material-ui/core/TextField";

const StatusFilter = ({ setStatusValue, intl }) => {
  const [value, setValue] = React.useState("noFilter");
  const classes = useStyles();

  const handleSetFilter = ({ target: { value } }) => {
    setValue(value);
    if (value === "noFilter") {
      setStatusValue(null);
    } else {
      setStatusValue({
        field: "classificationStatus",
        operand: "EQ",
        value: value,
      });
    }
  };

  const menuLabels = [
    "noFilter",
    "PENDING",
    "ERROR",
    "IN_PROGRESS",
    "FINISHED",
    "POSSIBLE_TO_START",
  ];

  return (
    <div className={classes.filterContainer}>
      <TextField
        select
        name="status filter"
        label={translateValue(intl, "classification.status.filter")}
        value={value}
        onChange={handleSetFilter}
        fullWidth
      >
        {menuLabels.map((option) => (
          <MenuItem key={option} value={option}>
            {translateValue(intl, "classification.status." + option)}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  filterContainer: {
    marginRight: theme.spacing(8),
    width: theme.spacing(18),
  },
}));

export default injectIntl(StatusFilter);
