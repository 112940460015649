import React, { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import { connectComponent } from "../../../actions/ActionUtils";
import { injectIntl } from "react-intl";
import { translateValue } from "../../../i18n/ClebreTranslator";
import { updateValidationState } from "./validation/validateUploadedFiles";
import { sendExaminationPackage } from "./sendExaminationPackage";
import { makeStyles } from "@material-ui/core/styles";
import { Backdrop } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogBase from "../../../component/Dialog/DialogBase";
import FileDropzone from "./FileDropzone";
import FileStatusTable from "./FileStatusTable";
import AdditionalInfo from "./AdditionalInformation";
import ProgressBar from "../../../component/Progress/ProgressBar";
import Toast from "../../../component/Toast/Toast";

const ExaminationUpload = ({
  intl,
  open,
  handleClose,
  profileId,
  setUploadResult,
  fetchProfile,
  ...props
}) => {
  const [validating, setValidating] = useState(false);
  const [isValidationError, setValidationError] = useState(false);
  const [isValid, setValid] = useState(false);
  const initialFileTypeState = {
    valid: false,
    message: "file.add",
    file: "",
    name: "",
    status: "add",
  };
  const initialState = {
    requiredFiles: {
      JSN: { ...initialFileTypeState },
      ACC: { ...initialFileTypeState },
      ENV: { ...initialFileTypeState },
      LOG: { ...initialFileTypeState },
      PAR: { ...initialFileTypeState },
      POX: { ...initialFileTypeState },
    },
    examinationId: { id: null, valid: false },
    sensorId: { id: null, valid: false },
    droppedFiles: {
      wrongExtension: [],
      wrongName: [],
      duplicates: [],
      tooBig: [],
    },
  };
  const [validationState, setValidationState] = useState(initialState);
  const [uploadStatus, setUploadStatus] = useState(0);

  const classes = useStyles();

  const handleReset = useCallback(() => {
    setValidationState(initialState);
    setUploadStatus(0);
  }, [initialState]);

  useEffect(() => {
    const finishUpload = () => {
      if (uploadStatus === 100) {
        handleReset();
        handleClose();
      }
    };
    // Without the timeout modal closes too soon
    setTimeout(finishUpload, 3000);
  }, [uploadStatus, handleClose, handleReset]);

  const processUploadedFiles = (files) => {
    setValidating(true);
    setValidationError(false);
    const newState = { ...validationState };

    updateValidationState(newState, files)
      .then((updatedState) => {
        setValidationState(updatedState);
        checkFiles(updatedState);
        setValidating(false);
      })
      .catch(() => {
        setValidating(false);
        setValidationError(true);
        handleReset();
      });
  };

  const checkFiles = (newState) => {
    const files = Object.values(newState.requiredFiles);

    const valid = _.isEmpty(files.filter((file) => file.status !== "success"));

    setValid(valid);
  };

  const handleSubmit = () => {
    if (isValid) {
      sendExaminationPackage(
        validationState.requiredFiles,
        validationState.examinationId.id,
        profileId,
        setUploadStatus
      )
        .then(() => {
          fetchProfile(profileId, false);
          setTimeout(() => {
            setUploadResult("success");
          }, 2000);
        })
        .catch(() => {
          setTimeout(() => {
            setUploadResult("error");
          }, 2000);
        });
    }
  };

  return (
    <DialogBase
      maxWidth="md"
      open={open}
      title={translateValue(intl, "examination.add")}
      submitText={translateValue(intl, "button.save")}
      handleSubmit={handleSubmit}
      handleReset={handleReset}
      handleClose={handleClose}
      isValid={isValid}
      {...props}
    >
      <FileDropzone processUploadedFiles={processUploadedFiles}></FileDropzone>
      {uploadStatus !== 0 && (
        <>
          <Backdrop open={true} classes={{ root: classes.overlay }} />
          <div>
            <Typography className={classes.uploadMessage}>
              {translateValue(intl, "examination.isUploading")}
            </Typography>
            <ProgressBar progress={uploadStatus} labelColor="white" />
          </div>
        </>
      )}
      {validating && <CircularProgress className={classes.progress} />}
      <FileStatusTable
        validationState={validationState}
        setValidationState={setValidationState}
        handleReset={handleReset}
      ></FileStatusTable>
      <AdditionalInfo validationState={validationState} handleReset={handleReset}></AdditionalInfo>
      {isValidationError && (
        <Toast severity="error" message={translateValue(intl, "examination.validation.error")} />
      )}
    </DialogBase>
  );
};

const useStyles = makeStyles((theme) => ({
  overlay: {
    zIndex: "10",
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  uploadMessage: {
    zIndex: "10",
    position: "relative",
    marginTop: theme.spacing(1),
    color: theme.palette.grey[50],
  },
  progress: {
    position: "absolute",
    top: "60%",
    right: "45%",
  },
}));

export default injectIntl(connectComponent(ExaminationUpload));
