import React, { useCallback, useEffect, useRef, useState } from "react";
import { injectIntl } from "react-intl";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import PaperBase from "../component/Paper/PaperBase";
import { CircularOnBackdrop } from "../component/Progress/CircularOnBackdrop";
import { generateColumnField } from "../component/ClebreTable/generateTableColumn";
import ClebreTableHeader from "../component/ClebreTable/ClebreTableHeader";
import ClassificationsFilterPanel from "./components/ClassificationsFilterPanel";
import ClebreTablePagination from "../component/ClebreTable/ClebreTablePagination";
import NoResultsRow from "../component/ClebreTable/NoResultsRow";
import DataRow from "./components/DataRow";
import Toast from "../component/Toast/Toast";
import { HttpService } from "../http-service/HttpService";
import { translateValue } from "../i18n/ClebreTranslator";

const Classification = (props) => {
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("classificationRoundNaturalId");
  const [sortOrder, setSortOrder] = useState("desc");
  const [classifications, setClassifications] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [actionStatus, setActionStatus] = useState("");

  const initialRender = useRef(true);

  const memoizedFetch = useCallback(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setLoading(true);
      HttpService.fetchClassificationsSummaryList(page, limit, sortOrder, sortField, filters)
        .then((response) => {
          setClassifications(response.content);
          setTotalRecords(response.totalElements);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [limit, page, sortField, sortOrder, filters]);

  useEffect(() => {
    memoizedFetch();
  }, [page, sortField, sortOrder]);

  useEffect(() => {
    if (page === 0) {
      memoizedFetch();
    } else {
      setPage(0);
    }
  }, [limit, filters]);

  const labelCellList = [
    { label: "classificationRoundNaturalId", isSortable: true },
    { label: "examinationNaturalId", isSortable: true },
    { label: "examinationStartDate", isSortable: true },
    { label: "classificationStartDate", isSortable: true },
    { label: "classificationEndDate", isSortable: true },
    { label: "classificationStatus", isSortable: false },
    { label: "classificationEpisodesCount", isSortable: true },
    { label: "percentOfEpisodesClassified", isSortable: false },
    { label: "percentOfEpisodesLabelMatchedWithAlgorithm", isSortable: false },
    { label: "classificationOptions", isSortable: false },
  ];

  const headCells = labelCellList.map((column) => {
    return generateColumnField(
      column.label,
      translateValue(props.intl, "classification." + column.label),
      column.isSortable,
      sortOrder,
      sortField,
      setSortOrder,
      setSortField
    );
  });

  const statusToast = [
    { actionStatus: "startSuccess", severity: "success" },
    { actionStatus: "startError", severity: "error" },
    { actionStatus: "stopSuccess", severity: "success" },
    { actionStatus: "stopError", severity: "error" },
  ];

  return (
    <div>
      {statusToast.map((s) => {
        return (
          actionStatus === s.actionStatus && (
            <Toast
              key={s.actionStatus}
              severity={s.severity}
              message={translateValue(props.intl, "classification.actionStatus." + s.actionStatus)}
              setUploadResult={setActionStatus}
            />
          )
        );
      })}
      <ClassificationsFilterPanel setFiltersCallback={setFilters} />
      <PaperBase>
        <TableContainer>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <ClebreTableHeader headCells={headCells} />
            <TableBody>
              {classifications.map((classification) => {
                return (
                  <DataRow
                    key={classification.examinationId + "-" + classification.classificationRoundId}
                    actionStatus={setActionStatus}
                    classification={classification}
                    setLoading={setLoading}
                    memoizedFetch={memoizedFetch}
                  />
                );
              })}
              {totalRecords === 0 && !loading && <NoResultsRow />}
            </TableBody>
          </Table>
        </TableContainer>
        <ClebreTablePagination
          page={page}
          limit={limit}
          totalRecords={totalRecords}
          setLimit={setLimit}
          setPage={setPage}
        />
      </PaperBase>
      {loading && <CircularOnBackdrop />}
    </div>
  );
};

export default injectIntl(Classification);
