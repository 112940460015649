import React from "react";
import { makeStyles } from "@material-ui/core/";
import clsx from "clsx";
import { injectIntl } from "react-intl";
import { translateValue } from "../i18n/ClebreTranslator";
import { Link } from "react-router-dom";

const AuthWrapper = ({ children, intl, ...props }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx({
        [classes.authContainer]: true,
        [classes.longContainer]: props.type === "register",
        [classes.shortContainer]: props.type === "login",
      })}
    >
      <img src="images/logo_horiz_1600px.png" alt="clebreLogo" className={classes.logo} />
      {children}
      <div className={classes.footer}>
        <Link to="#" className={classes.link}>
          {translateValue(intl, "login.cookieFiles")}
        </Link>
        <Link to="#" className={classes.link}>
          {translateValue(intl, "login.help")}
        </Link>
        <Link to="#" className={classes.link}>
          {translateValue(intl, "login.privacyPolicy")}
        </Link>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  authContainer: {
    marginTop: "5vh",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "35vw",
    justifyContent: "space-between",
    minWidth: "300px",

    "@media (max-height:700px)": {
      "& > *": {
        margin: theme.spacing(0.5, 0, 0.5, 0),
      },
    },
    "@media (max-width:1100px)": {
      width: "60vw",
    },
    [theme.breakpoints.down("md")]: {
      height: "40vh",
    },
    [theme.breakpoints.up("xl")]: {
      width: "30vw",
    },
  },
  longContainer: {
    marginTop: "2vh",
    height: "90vh",

    "@media (min-height:800px)": {
      height: "70vh",
      marginTop: "10vh",
    },

    [theme.breakpoints.up("xl")]: {
      height: "70vh",
      marginTop: "10vh",
    },
  },
  shortContainer: {
    height: "80vh",

    "@media (min-height:800px)": {
      height: "60vh",
      marginTop: "10vh",
    },

    [theme.breakpoints.up("xl")]: {
      height: "60vh",
      marginTop: "10vh",
    },
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",

    "& > *": {
      margin: theme.spacing(0, 1, 0, 1),
      color: theme.palette.primary.main,
    },
  },
  logo: {
    width: "250px",

    [theme.breakpoints.down("s")]: {
      width: "200px",
    },

    [theme.breakpoints.up("xl")]: {
      width: "300px",
    },
  },
}));

export default injectIntl(AuthWrapper);
