import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";

class ClebreDetailsSingleColumn extends Component {
  render() {
    return (
      <Grid item xs>
        <b>
          {translateValue(this.props.intl, this.props.caption)}
          :&nbsp;
        </b>
        {this.props.data}
      </Grid>
    );
  }
}

export default injectIntl(ClebreDetailsSingleColumn);
