import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import { HttpService } from "../../http-service/HttpService";
import { injectIntl } from "react-intl";
import { translateValue } from "../../i18n/ClebreTranslator";
import { connectComponent } from "../../actions/ActionUtils";
import { useFormik } from "formik";
import AuthWrapper from "../AuthWrapper";
import ProfileForm from "../../profiles/ProfileForm";
import { profileValidationSchema } from "../../profiles/profileValidationSchema";
import { processFormValues } from "../../util/helpers";

const FirstProfileForm = ({ user, intl, actions }) => {
  const [govIdValidationCache] = useState({});
  const validationSchema = profileValidationSchema(user, intl, govIdValidationCache);
  const [createProfileError, setCreateProfileError] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      govIdentification: "",
      dateOfBirth: "",
      gender: "",
      height: "",
      weight: "",
      phoneNumber: "",
    },
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      values["accountId"] = user.id;
      const normalizedValues = processFormValues(values);

      HttpService.addNewProfile(normalizedValues)
        .then(() => {
          HttpService.getLoggedInUser().then((user) => {
            actions.userLoggedIn(user);
            history.push("/profiles");
            resetForm();
            setLoading(false);
          });
        })
        .catch(() => {
          setCreateProfileError(true);
          setLoading(false);
        });
    },
    validationSchema: validationSchema,
    validateOnMount: true,
  });

  const showSuccessAlert = user.active && !createProfileError && !loading;

  return (
    <AuthWrapper type="register">
      <Typography variant="h6" align="center">
        {translateValue(intl, "register.createProfileFor")}
        <span className={classes.bold}>{user.email}</span>
      </Typography>
      {showSuccessAlert && (
        <Alert className={classes.alert} severity="success">
          {translateValue(intl, `register.accountActivated`)}
        </Alert>
      )}
      {createProfileError && (
        <Alert className={classes.alert} severity="error">
          {translateValue(intl, `register.createProfileError`)}
        </Alert>
      )}
      {loading && <CircularProgress />}
      <ProfileForm
        className={classes.profileForm}
        govIdValidationCache={govIdValidationCache}
        formik={formik}
        type="firstAdd"
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!formik.isValid || loading}
        onClick={formik.handleSubmit}
      >
        {translateValue(intl, "register.createProfile")}
      </Button>
    </AuthWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
  },
  alert: {
    width: "100%",
    marginBottom: 0,
  },
  profileForm: {
    marginTop: 0,
  },
}));

export default connectComponent(injectIntl(FirstProfileForm));
