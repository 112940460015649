export const getUserCountry = () => {
  const browserLocales =
    navigator.languages === undefined ? [navigator.language] : navigator.languages;

  if (!browserLocales) {
    return undefined;
  }

  let guessedCountry = undefined;
  browserLocales.forEach((locale) => {
    if (locale.length > 2) {
      guessedCountry = locale.split(/-|_/)[1].toLowerCase();
    }
  });

  return guessedCountry;
};
