import React, { Component } from "react";
import { HttpService } from "../http-service/HttpService";
import moment from "moment";
import momentLocalizer from "react-widgets-moment";
import { connectComponent } from "../actions/ActionUtils";
import { injectIntl } from "react-intl";
import "react-widgets/dist/css/react-widgets.css";
import "react-table-v6/react-table.css";
import { findExaminationDuration, getTodayDate, getYesterdayDate } from "../util/helpers";
import DateTimeValue from "../component/date-time-value/DateTimeValue";
import { withStyles } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import PaperBase from "../component/Paper/PaperBase";
import TablePagination from "@material-ui/core/TablePagination";
import Grid from "@material-ui/core/Grid";
import ClebreTableHeader from "../component/ClebreTable/ClebreTableHeader";
import SearchBox from "../component/search-box/SearchBox";
import { CircularOnBackdrop } from "../component/Progress/CircularOnBackdrop";
import { translateValue } from "../i18n/ClebreTranslator";
import { convertTimezone } from "../util/timezoneConverter";
class Examinations extends Component {
  constructor(props) {
    super(props);
    momentLocalizer();
    this.state = {
      examinationsByTime: null,
      limit: 10,
      totalRecords: 0,
      page: 0,
      loading: false,
      activeSortField: "addedAt",
      activeSortDirection: "desc",
      searchPhrase: "",
    };
  }

  componentDidMount() {
    this.fetchExaminations();
  }

  handleRowsPerPage = (event) => {
    this.setState({ limit: event.target.value }, () => this.fetchExaminations());
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage }, () => this.fetchExaminations());
  };

  static parseProfileId() {
    const queryString = require("query-string");
    const parsedUri = window.location.hash.split("?")[1];
    const parsedParams = queryString.parse(parsedUri);
    return parsedParams.profileId;
  }

  fetchExaminations() {
    this.setState({
      loading: true,
    });
    HttpService.paginateExaminations(
      this.state.page,
      this.state.limit,
      this.state.searchPhrase,
      this.state.activeSortField,
      this.state.activeSortDirection
    )
      .then((response) => {
        this.setState({
          examinationsByTime: response.content,
          totalRecords: response.totalElements,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
      });
  }

  detailsButtonClick = (examination) => {
    const examinationId = examination.examinationId;
    this.props.history.push(`/examinationDetails/${examinationId}`);
  };

  handleSorting = (field) => {
    const direction = this.state.activeSortDirection === "asc" ? "desc" : "asc";
    this.setState(
      {
        activeSortField: field,
        activeSortDirection: direction,
      },
      () => this.fetchExaminations()
    );
  };

  handleSortDirection(field) {
    return this.state.activeSortField === field ? this.state.activeSortDirection : "asc";
  }

  generateColumnField(columnName, label) {
    const sortableColumns = ["naturalId", "lastname", "startedAt", "endedAt"];
    const isSortable = sortableColumns.includes(columnName);

    return {
      id: columnName,
      align: "left",
      label: label,
      isSortable: isSortable,
      activeSorting: this.state.activeSortField === columnName,
      handleSorting: () => this.handleSorting(columnName),
      sortingDirection: this.handleSortDirection(columnName),
    };
  }

  handleSearchChange(event) {
    this.setState({ searchPhrase: event.target.value });
  }

  findPatientName = (examination) => {
    const lastName = examination.profiles.filter(
      (profile) => profile.participationType === "PATIENT"
    )[0].profileDTO.lastName;
    const firstName = examination.profiles.filter(
      (profile) => profile.participationType === "PATIENT"
    )[0].profileDTO.firstName;

    return `${firstName} ${lastName}`;
  };

  findExaminationDuration = (examination) => {
    let start = moment(examination.startDateTime);
    let end = moment(examination.endDateTime);

    let duration = moment(end.diff(start)).format("h:mm:ss");

    return duration;
  };

  generateBasicColumns() {
    return [
      this.generateColumnField("addedAt", translateValue(this.props.intl, "examination.addedAt")),
      this.generateColumnField(
        "naturalId",
        translateValue(this.props.intl, "examination.naturalId")
      ),
      this.generateColumnField("lastname", translateValue(this.props.intl, "firstNameAndLastName")),
      this.generateColumnField(
        "profileGovIdentification",
        translateValue(this.props.intl, "govid")
      ),
      this.generateColumnField("startedAt", translateValue(this.props.intl, "examination.start")),
      this.generateColumnField("endedAt", translateValue(this.props.intl, "examination.end")),
      this.generateColumnField(
        "formattedDuration",
        translateValue(this.props.intl, "examination.durationInSeconds")
      ),
    ];
  }

  generateAdminColumns() {
    return [
      this.generateColumnField("addedAt", translateValue(this.props.intl, "examination.addedAt")),
      this.generateColumnField(
        "naturalId",
        translateValue(this.props.intl, "examination.naturalId")
      ),
      this.generateColumnField("lastname", translateValue(this.props.intl, "firstNameAndLastName")),
      this.generateColumnField("startedAt", translateValue(this.props.intl, "examination.start")),
      this.generateColumnField("endedAt", translateValue(this.props.intl, "examination.end")),
      this.generateColumnField(
        "formattedDuration",
        translateValue(this.props.intl, "examination.durationInSeconds")
      ),
      this.generateColumnField(
        "hasRawFiles",
        translateValue(this.props.intl, "examination.hasRawFiles")
      ),
    ];
  }

  renderTableContent(headCells) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SearchBox
            fetchData={() => this.fetchExaminations()}
            searchPhrase={this.state.searchPhrase}
            onSearchChange={(event) => this.handleSearchChange(event)}
          />
        </Grid>
        <Grid item xs={12}>
          <PaperBase>
            <TableContainer>
              <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                <ClebreTableHeader headCells={headCells} />
                <TableBody>
                  {this.state.examinationsByTime &&
                    this.state.examinationsByTime.length > 0 &&
                    this.state.examinationsByTime.map((row) => {
                      const formattedAddedAt = row.addedAt.split("T")[0];
                      const today = getTodayDate();
                      const yesterday = getYesterdayDate();

                      return (
                        <TableRow
                          key={`${row.examinationId}`}
                          onClick={() => this.detailsButtonClick(row)}
                          className={this.props.classes.tableRowClicked}
                        >
                          <TableCell>
                            {formattedAddedAt === today && translateValue(this.props.intl, "today")}
                            {formattedAddedAt === yesterday &&
                              translateValue(this.props.intl, "yesterday")}
                            {formattedAddedAt !== today && formattedAddedAt !== yesterday && (
                              <DateTimeValue
                                datetime={convertTimezone(row.addedAt, this.props.timezone)}
                              />
                            )}
                          </TableCell>
                          <TableCell component="th" scope="row" padding="none">
                            {row.examinationNaturalId}
                          </TableCell>
                          <TableCell align="left">{this.findPatientName(row)}</TableCell>
                          {row.examinationPatientSearchResponse ? (
                            <TableCell align="left">{row.profileGovIdentification}</TableCell>
                          ) : null}
                          <TableCell>
                            <DateTimeValue
                              datetime={convertTimezone(row.startDateTime, this.props.timezone)}
                            />
                          </TableCell>
                          <TableCell>
                            <DateTimeValue
                              datetime={convertTimezone(row.endDateTime, this.props.timezone)}
                            />
                          </TableCell>
                          <TableCell align="left">{findExaminationDuration(row)}</TableCell>
                          {row ? (
                            <TableCell align="left">
                              {row.examinationFiles.length > 0
                                ? translateValue(this.props.intl, "yes")
                                : translateValue(this.props.intl, "no")}
                            </TableCell>
                          ) : null}
                        </TableRow>
                      );
                    })}
                  {this.state.examinationsByTime && this.state.examinationsByTime.length === 0 && (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        {translateValue(this.props.intl, "noDataFound")}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              labelRowsPerPage={translateValue(this.props.intl, "table.rowsPerPage")}
              labelDisplayedRows={({ from, to, count }) => {
                return ` ${from} - ${to} ${translateValue(this.props.intl, "of")} ${count}`;
              }}
              page={this.state.page}
              rowsPerPage={this.state.limit}
              count={this.state.totalRecords}
              onChangePage={(event, newPage) => this.handleChangePage(event, newPage)}
              onChangeRowsPerPage={(event) => this.handleRowsPerPage(event)}
            />
          </PaperBase>
          {this.state.loading && <CircularOnBackdrop />}
        </Grid>
      </Grid>
    );
  }

  renderTablePerRole = () => {
    const adminColumns = this.generateAdminColumns();
    const basicColumns = this.generateBasicColumns();
    const tablePerRole = {
      ADMIN: () => this.renderTableContent(adminColumns),
      BASIC: () => this.renderTableContent(basicColumns),
      DOCTOR: () => this.renderTableContent(basicColumns),
      RESEARCHER: () => this.renderTableContent(basicColumns),
    };

    return tablePerRole[this.props.user.currentGroup.name]();
  };

  render() {
    return this.renderTablePerRole();
  }
}

const styles = (theme) => ({
  tableRowClicked: {
    cursor: "pointer",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.03)",
    },
  },
});

export default withStyles(styles)(injectIntl(connectComponent(Examinations)));
