import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const DateInput = ({ label, InputProps, min, max, ...props }) => {
  const classes = useStyles();

  return (
    <TextField
      {...props}
      label={label}
      type="date"
      className={classes.dateInput}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={InputProps}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  dateInput: {
    marginRight: theme.spacing(8),
  },
}));

export default DateInput;
