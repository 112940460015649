import { HttpService } from "../../../../http-service/HttpService";

export const validateWithApiCalls = ([validationState, processedFiles]) => {
  const selectedJSN = validationState.requiredFiles["JSN"];

  // if no JSN or JSN is invalid, resolve promise and proceed to the next function
  if (!selectedJSN.file || !selectedJSN.valid) {
    return Promise.resolve([validationState, processedFiles]);
  }
  // If JSN is valid, validate examinationID and sensorID
  return Promise.all([
    validateExaminationId([validationState, processedFiles]),
    validateSensor([validationState, processedFiles]),
  ])
    .then(() => {
      return Promise.resolve([validationState, processedFiles]);
    })
    .catch((error) => {
      throw error;
    });
};

const validateSensor = ([validationState, processedFiles]) => {
  return HttpService.getSensor(validationState.sensorId.id)
    .then(() => {
      // If the response is found (200), sensor serial is valid
      validationState.sensorId.valid = true;
    })
    .catch((error) => {
      // if the response is 'not found' sensor serial is invalid
      if (error.response.status === 404) {
        validationState.sensorId.valid = false;
        validationState.requiredFiles["JSN"].valid = false;
        validationState.requiredFiles["JSN"].message = "examination.wrongSensor";
        return Promise.resolve([validationState, processedFiles]);
      } else {
        throw error;
      }
    });
};

const validateExaminationId = ([validationState, processedFiles]) => {
  const base64examinationId = btoa(validationState.examinationId.id);
  return HttpService.findExaminationByRecordId(validationState.sensorId.id, base64examinationId)
    .then(() => {
      // if the response is found (200) examinationID is invalid (duplicated)
      validationState.examinationId.valid = false;
      validationState.requiredFiles["JSN"].valid = false;
      validationState.requiredFiles["JSN"].message = "examination.duplicateExamination";
      return Promise.resolve([validationState, processedFiles]);
    })
    .catch((error) => {
      if (error.response.status === 404) {
        // examinationID is valid if not found in the database
        validationState.examinationId.valid = true;
        return Promise.resolve([validationState, processedFiles]);
      } else {
        throw error;
      }
    });
};
