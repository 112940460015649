import React from "react";
import { injectIntl } from "react-intl";
import DateInput from "../input/DateInput";
import { translateValue } from "../../i18n/ClebreTranslator";

const DateFilter = ({ field, operand, setDateFilter, label, intl }) => {
  const handleChange = (event) => {
    let value = event.target.value.trim();
    if (value === "") {
      setDateFilter(null);
    } else {
      setDateFilter({
        field: field,
        operand: operand,
        value: value,
      });
    }
  };

  return (
    <DateInput
      onChange={handleChange}
      label={translateValue(intl, label)}
      InputProps={{
        inputProps: {
          min: "2000-01-01",
          max: new Date().toISOString().split("T")[0],
        },
      }}
    />
  );
};

export default injectIntl(DateFilter);
