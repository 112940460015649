import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connectComponent } from "../../actions/ActionUtils";
import RegisterForm from "./RegisterForm";
import FirstProfileForm from "./FirstProfileForm";
import EmailSent from "./EmailSent";
import { CircularOnBackdrop } from "../../component/Progress/CircularOnBackdrop";

const RegisterWrapper = ({ user }) => {
  const steps = {
    REGISTER: 1,
    CONFIRM_EMAIL: 2,
    CREATE_PROFILE: 3,
  };
  const [activeStep, setActiveStep] = useState(null);

  useEffect(() => {
    const getActiveStep = () => {
      if (!_.isEmpty(user) && !user.active) {
        setActiveStep(steps.CONFIRM_EMAIL);
      } else if (!_.isEmpty(user) && user.active) {
        setActiveStep(steps.CREATE_PROFILE);
      } else {
        setActiveStep(steps.REGISTER);
      }
    };

    getActiveStep();
  });

  const getStepContent = (step) => {
    switch (step) {
      case steps.REGISTER:
        return <RegisterForm />;
      case steps.CONFIRM_EMAIL:
        return <EmailSent />;
      case steps.CREATE_PROFILE:
        return <FirstProfileForm />;
      default:
        return <RegisterForm />;
    }
  };

  if (!activeStep) {
    return <CircularOnBackdrop />;
  } else {
    return <>{getStepContent(activeStep)}</>;
  }
};

export default connectComponent(RegisterWrapper);
