export const weightHeightHandleChange = (event, handleChange) => {
  // allow only digits
  event.target.value = event.target.value.replace(/[^0-9]+/g, "");

  if (event.currentTarget.value.length > 3) {
    return;
  } else {
    handleChange(event);
  }
};
