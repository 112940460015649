import React, { Component } from "react";
import { HttpService } from "../http-service/HttpService";
import { connectComponent } from "../actions/ActionUtils";
import ClebreFormModal from "../component/clebre-form/ClebreFormModal";
import { translateValue } from "../i18n/ClebreTranslator";
import { SensorStatuses } from "./SensorEnums";
import { FormInputBase } from "../component/input/FormInputBase";
import { injectIntl } from "react-intl";

class SensorEdit extends Component {
  constructor(props) {
    super(props);

    this.sensorStatuses = SensorStatuses.map((value) => ({
      value: value,
      label: translateValue(this.props.intl, `sensor.sensorStatus.${value}`),
    }));

    this.state = {
      serialNumber: props.sensorToEdit.serialNumber,
      createdDate: "",
      sensorStatus: "",
      firmwareVersion: "",
      hardwareRevision: "",
      customLabel: "",
      macAddress: "",
    };
  }

  componentDidMount() {
    HttpService.getSensor(this.state.serialNumber).then((sensor) => {
      this.setState(sensor);
    });
  }

  onChangeSensorStatus(event) {
    this.setState({
      sensorStatus: event.target.value,
    });
  }

  updateAndClose() {
    HttpService.updateSensor(this.state.serialNumber, {
      firmwareVersion: this.state.firmwareVersion,
      hardwareRevision: this.state.hardwareRevision,
      customLabel: this.state.customLabel,
      sensorStatus: this.state.sensorStatus,
      macAddress: this.state.macAddress,
    }).then(() => {
      this.props.actions.closeEditSensorModal();
      this.props.loadSensors();
    });
  }

  renderForm() {
    return (
      <div>
        <div>
          <FormInputBase
            label={translateValue(this.props.intl, "sensor.serialNumber")}
            value={this.state.serialNumber}
            disabled={true}
          />
        </div>

        <div>
          <FormInputBase
            select
            label={translateValue(this.props.intl, "sensor.sensorStatus")}
            value={this.state.sensorStatus || ""}
            onChange={(event) => {
              this.onChangeSensorStatus(event);
            }}
            SelectProps={{
              native: true,
            }}
          >
            {this.sensorStatuses.map((sensorStatus) => (
              <option key={sensorStatus.value} value={sensorStatus.value}>
                {sensorStatus.label}
              </option>
            ))}
          </FormInputBase>
        </div>
        <div>
          <FormInputBase
            label={translateValue(this.props.intl, "sensor.firmwareVersion")}
            value={this.state.firmwareVersion}
            onChange={(event) => this.setState({ firmwareVersion: event.target.value })}
          />
        </div>
        <div>
          <FormInputBase
            label={translateValue(this.props.intl, "sensor.hardwareRevision")}
            value={this.state.hardwareRevision}
            onChange={(event) => this.setState({ hardwareRevision: event.target.value })}
          />
        </div>
        <div>
          <FormInputBase
            label={translateValue(this.props.intl, "sensor.macAddress")}
            value={this.state.macAddress}
            onChange={(event) => this.setState({ macAddress: event.target.value })}
          />
        </div>
        <div>
          <FormInputBase
            label={translateValue(this.props.intl, "sensor.customLabel")}
            value={this.state.customLabel}
            onChange={(event) => this.setState({ customLabel: event.target.value })}
          />
        </div>
      </div>
    );
  }

  render() {
    return (
      <ClebreFormModal
        i18nLabelTitle="modal.headingEditSensor"
        modalClass="sensorEdit"
        formBody={this.renderForm()}
        onSave={() => this.updateAndClose()}
        show={!!this.props.sensorToEdit}
        onHide={() => this.props.actions.closeEditSensorModal()}
      />
    );
  }
}

export default injectIntl(connectComponent(SensorEdit));
