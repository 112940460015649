export const generateColumnField = (
  columnName,
  label,
  isSortable,
  sortOrder,
  sortField,
  setSortOrder,
  setSortField
) => {
  sortOrder = sortOrder ? sortOrder.toLowerCase() : null;
  const handleSorting = (field) => {
    const order = sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const handleSortDirection = (field) => {
    return sortField === field ? sortOrder : "asc";
  };

  return {
    id: columnName,
    align: "left",
    label: label,
    isSortable: isSortable,
    activeSorting: sortField === columnName,
    handleSorting: () => handleSorting(columnName),
    sortingDirection: handleSortDirection(columnName),
  };
};
