import { toast } from "react-toastify";

export function notifyError(message) {
  return toast.error(message);
}

export function notifySuccess(message) {
  return toast.success(message);
}

export function showInfo(message, seconds) {
  return toast.info(message, {
    autoClose: seconds * 1000,
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: false,
    closeButton: false,
  });
}
