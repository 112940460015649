import React from "react";
import { injectIntl } from "react-intl";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PaperBase from "../../component/Paper/PaperBase";
import ApplyFilterButton from "../../component/Button/ApplyFilterButton";
import { translateValue } from "../../i18n/ClebreTranslator";
import StatusFilter from "./StatusFilter";
import DateFilter from "../../component/filters/DateFilter";

const ClassificationsFilterPanel = ({ setFiltersCallback, intl }) => {
  const [statusValue, setStatusValue] = React.useState(null);
  const [dataFromFilter, setDateFromFilter] = React.useState(null);
  const [dataToFilter, setDateToFilter] = React.useState(null);
  const classes = useStyles();

  const handleSetFilers = () => {
    setFiltersCallback(
      [statusValue, dataFromFilter, dataToFilter].filter((value) => value !== null)
    );
  };

  return (
    <PaperBase classes={{ root: classes.filterArea }}>
      <Typography variant="h6">{translateValue(intl, "filters")}</Typography>
      <div className={classes.filters}>
        <DateFilter
          label={"classification.dateFilter.from"}
          setDateFilter={setDateFromFilter}
          field={"classificationStartDate"}
          operand={"GTE"}
        />
        <DateFilter
          label={"classification.dateFilter.to"}
          setDateFilter={setDateToFilter}
          field={"classificationStartDate"}
          operand={"LTE"}
        />
        <StatusFilter setStatusValue={setStatusValue} />
        <ApplyFilterButton onClick={handleSetFilers}>
          {translateValue(intl, "button.filter")}
        </ApplyFilterButton>
      </div>
    </PaperBase>
  );
};

const useStyles = makeStyles((theme) => ({
  filterArea: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  filters: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "flex-end",
  },
}));

export default injectIntl(ClassificationsFilterPanel);
