import React from "react";
import _ from "lodash";
import { DropzoneArea } from "material-ui-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { translateValue } from "../../../i18n/ClebreTranslator";
import { acceptedFileExtensions, maxFileSize, fileNumberLimit } from "./constants";

const FileDropzone = ({ intl, processUploadedFiles }) => {
  const classes = useStyles();

  const onDrop = (files) => {
    if (!_.isEmpty(files)) {
      processUploadedFiles(files);
    }
  };

  return (
    <DropzoneArea
      onDrop={(files) => onDrop(files)}
      showPreviews={false}
      showPreviewsInDropzone={false}
      // user is not restricted in number of uploaded files
      // validation will always choose 6 of them
      filesLimit={fileNumberLimit}
      onDropRejected={onDrop}
      showAlerts={false}
      disableRejectionFeedback={true}
      dropzoneText={translateValue(intl, "dropzone.helper")}
      classes={{ icon: classes.dropZoneIcon, root: classes.fileDropzone }}
      maxFileSize={maxFileSize}
      acceptedFiles={acceptedFileExtensions}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  fileDropzone: {
    color: theme.palette.grey[500],
  },
  dropZoneIcon: {
    color: theme.palette.grey[500],
  },
}));

export default injectIntl(FileDropzone);
