import _ from "lodash";

export const translateValue = (intl, idToTranslate) => {
  if (!idToTranslate) {
    return;
  }
  const { messages, formatMessage } = intl;
  if (_.has(messages, idToTranslate)) {
    return formatMessage({ id: idToTranslate });
  } else {
    return "i18n missing: " + idToTranslate;
  }
};
