import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as UiActions from "../../actions/Actions";
import { HttpService } from "../../http-service/HttpService";
import { useHistory } from "react-router-dom";
import { decodeTokenAndLogin } from "../../util/decodeTokenAndLogin";

const AccountActivation = ({
  actions,
  user,
  match: {
    params: { userId, code },
  },
}) => {
  const history = useHistory();
  const activationCompleted = useRef(false);

  useEffect(() => {
    const activateUser = () => {
      HttpService.activateUser(userId, code)
        .then((response) => {
          if (response.headers.authorization) {
            activationCompleted.current = true;
            decodeTokenAndLogin(response.headers.authorization, actions);
            history.push("/");
          }
        })
        .catch(() => {
          history.push("/");
          actions.userLoggedOut();
          actions.accountActivationFailed(true);
        });
    };

    if (activationCompleted.current) {
      return;
    }

    if (user) {
      actions.userLoggedOut();
      return;
    }

    // no user logged in - activation
    if (userId && code) {
      activateUser();
    }
  }, [actions, user, code, history, userId]);

  return <></>;
};

function mapState(state) {
  return {
    user: state.user,
  };
}

function mapActions(dispatch) {
  return {
    actions: bindActionCreators(UiActions, dispatch),
  };
}

export default connect(mapState, mapActions)(AccountActivation);
