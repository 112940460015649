import React from "react";
import { injectIntl } from "react-intl";
import { connectComponent } from "../../actions/ActionUtils";
import { translateValue } from "../../i18n/ClebreTranslator";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import InputAdornment from "@material-ui/core/InputAdornment";
import { weightHeightHandleChange } from "../../util/customHandleChange";

const WeightOrHeightInput = (props) => {
  const { intl, user, type } = props;
  const { values, handleBlur, handleChange, errors, touched } = props.formik;
  const classes = useStyles();

  const fillData =
    errors.firstName ||
    errors.lastName ||
    errors.govIdentification ||
    errors.dateOfBirth ||
    errors.gender;

  return (
    <>
      <TextField
        required={user.currentGroup.name === "DOCTOR"}
        name={type}
        label={translateValue(intl, `${type}`)}
        value={values[type]}
        onChange={(event) => weightHeightHandleChange(event, handleChange)}
        onBlur={handleBlur}
        margin="dense"
        className={classes.numberInput}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">{type === "weight" ? "kg" : "cm"}</InputAdornment>
          ),
        }}
        autoComplete="off"
        error={errors[type] && touched[type]}
        helperText={errors[type] && touched[type] ? errors[type] : null}
      />
      {fillData && (
        <Typography variant="body1" className={classes.fillData}>
          {translateValue(intl, "profile.fillData")}
        </Typography>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  numberInput: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  fillData: {
    marginTop: theme.spacing(2),
    color: theme.palette.error.dark,
    width: theme.spacing(24),
  },
}));

export default injectIntl(connectComponent(WeightOrHeightInput));
