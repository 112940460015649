import React, { Component } from "react";
import { HttpService } from "../http-service/HttpService";
import { connectComponent } from "../actions/ActionUtils";
import ExaminationFiles from "./details/files/ExaminationFiles";
import SegmentationResults from "./details/results/SegmentationResults";
import ExaminationClassification from "./details/classification/ExaminationClassification";
import DateTimeValue from "../component/date-time-value/DateTimeValue";
import {
  formatDate,
  formatDurationInSeconds,
  getAge,
  getBMI,
  getDuration,
} from "../util/Formatter";
import { withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { CircularOnBackdrop } from "../component/Progress/CircularOnBackdrop";
import ClebreDetailsColumn from "../component/examination-details/ClebreDetailsColumn";
import ClebreDetailsSingleColumn from "../component/examination-details/ClebreDetailsSingleColumn";
import ClebreDetailsImage from "../component/examination-details/ClebreDetailsImage";
import TabPanel from "../component/tabs/TabPanel";
import { translateValue } from "../i18n/ClebreTranslator";
import { injectIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import { convertTimezone } from "../util/timezoneConverter";
import { hasPermissions, permissions } from "../util/validatePermissions";

class ExaminationDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentToShow: 0,
      examinationId: this.props.match.params.examinationId,
      examinationData: null,
      patientData: null,
      segmentationData: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.findExaminationData();
  }

  findExaminationData = () => {
    this.setState({
      loading: true,
    });
    HttpService.findExaminationById(this.state.examinationId).then((examinationData) => {
      this.setState({
        examinationData: examinationData,
      });
      this.getPatientData();
    });
  };

  findPatientId = (examination) => {
    const patientId = examination.profiles.filter(
      (profile) => profile.participationType === "PATIENT"
    )[0].profileDTO.profileId;

    return patientId;
  };

  getPatientData() {
    const patientId = this.findPatientId(this.state.examinationData);
    HttpService.getProfileById(patientId)
      .then((profile) => {
        this.setState({
          patientData: profile,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }

  renderExaminationDetails() {
    const dateTimeStart = this.state.examinationData
      ? this.state.examinationData.startDateTime
      : "";
    const dateTimeEnd = this.state.examinationData ? this.state.examinationData.endDateTime : "";
    return this.state.examinationData ? (
      <>
        <Grid container className={this.props.classes.examinationDetailCenter}>
          <ClebreDetailsImage image={"images/examination-icon.svg"} firstLabel={"examination"} />
          <ClebreDetailsColumn
            captions={["startLocalDate", "absoluteTime"]}
            stats={[
              <DateTimeValue datetime={convertTimezone(dateTimeStart, this.props.timezone)} />,
              formatDate(
                convertTimezone(dateTimeStart, this.props.timezone),
                "YYYY-MM-DD HH:mm:ss"
              ),
            ]}
          />
          <ClebreDetailsColumn
            captions={["endLocalDate", "absoluteTime"]}
            stats={[
              <DateTimeValue datetime={convertTimezone(dateTimeEnd, this.props.timezone)} />,
              formatDate(convertTimezone(dateTimeEnd, this.props.timezone), "YYYY-MM-DD HH:mm:ss"),
            ]}
          />
          <ClebreDetailsColumn
            captions={["registrationTime", "TRT"]}
            stats={[formatDurationInSeconds(getDuration(dateTimeStart, dateTimeEnd))]}
          />
          <Grid item xs />
        </Grid>
        <Grid container className={this.props.classes.examinationDetailCenter}>
          <Grid item xs />
          <ClebreDetailsSingleColumn
            caption={"examination.sensorSerialNumber"}
            data={this.state.examinationData.sensorSerialNumber}
          />
          <ClebreDetailsSingleColumn
            caption={"examination.naturalId"}
            data={this.state.examinationData.examinationNaturalId}
          />
          <ClebreDetailsSingleColumn
            caption={"firmware"}
            data={this.state.examinationData.firmwareVersion}
          />
          <Grid item xs />
        </Grid>
      </>
    ) : null;
  }

  renderProfileDetails() {
    return this.state.patientData ? (
      <Grid container className={this.props.classes.examinationDetailCenter}>
        <ClebreDetailsSingleColumn
          caption={"firstNameAndLastName"}
          data={`${this.state.patientData.firstName} ${this.state.patientData.lastName}`}
        />
        <ClebreDetailsSingleColumn
          caption={"profile.age"}
          data={getAge(this.state.patientData.dateOfBirth)}
        />
        <ClebreDetailsSingleColumn
          caption={"profile.height"}
          data={this.state.patientData.height}
        />
        <ClebreDetailsSingleColumn
          caption={"profile.weight"}
          data={this.state.patientData.weight}
        />
        <ClebreDetailsSingleColumn
          caption={"BMI"}
          data={getBMI(this.state.patientData.height, this.state.patientData.weight)}
        />
      </Grid>
    ) : null;
  }

  handleChange = (event, newValue) => {
    this.setState({ contentToShow: newValue });
  };

  renderNavbar() {
    return (
      <div className={this.props.classes.examinationDetailsTabsRoot}>
        <div>
          <Paper elevation={3} className={this.props.classes.examinationDetailsTabsSpacing}>
            <Tabs
              value={this.state.contentToShow}
              className={this.props.classes.examinationDetailsTabs}
              variant="scrollable"
              orientation="vertical"
              indicatorColor="primary"
              textColor="primary"
              onChange={this.handleChange}
              aria-label="disabled tabs example"
            >
              <Tab
                label={translateValue(this.props.intl, "results")}
                {...{
                  id: `vertical-tab-${0}`,
                  "aria-controls": `vertical-tabpanel-${0}`,
                }}
              />
              <Tab
                label={translateValue(this.props.intl, "data")}
                disabled={
                  !hasPermissions(
                    [permissions.FILE_LIST, permissions.FILE_LIST_GLOBAL],
                    this.props.user
                  )
                }
                {...{
                  id: `vertical-tab-${1}`,
                  "aria-controls": `vertical-tabpanel-${1}`,
                }}
              />
              {hasPermissions([permissions.EXPERT_CLASSIFICATION_START], this.props.user) ? (
                <Tab
                  label={translateValue(this.props.intl, "expertClassification")}
                  {...{
                    id: `vertical-tab-${2}`,
                    "aria-controls": `vertical-tabpanel-${2}`,
                  }}
                />
              ) : null}
            </Tabs>
          </Paper>
          {this.state.patientData ? (
            <Paper
              elevation={3}
              className={this.props.classes.examinationDetailsLink}
              onClick={() => this.props.history.goBack()}
            >
              {`${this.state.patientData.firstName} ${this.state.patientData.lastName}`}
            </Paper>
          ) : null}
        </div>
        {this.state.examinationData && this.state.examinationData.sleepScore && (
          <TabPanel value={this.state.contentToShow} index={0}>
            <SegmentationResults
              examinationData={this.state.examinationData ? this.state.examinationData : null}
            />
          </TabPanel>
        )}
        <TabPanel value={this.state.contentToShow} index={1}>
          <ExaminationFiles
            examinationFiles={
              this.state.examinationData ? this.state.examinationData.examinationFiles : null
            }
            examinationId={
              this.state.examinationData ? this.state.examinationData.examinationId : null
            }
            recordId={this.state.examinationData ? this.state.examinationData.recordId : null}
            findExaminationData={this.findExaminationData}
          />
        </TabPanel>
        <TabPanel value={this.state.contentToShow} index={2}>
          <ExaminationClassification
            examinationId={
              this.state.examinationData ? this.state.examinationData.examinationId : null
            }
            examinationFiles={
              this.state.examinationData ? this.state.examinationData.examinationFiles : []
            }
          />
        </TabPanel>
        {this.state.loading && <CircularOnBackdrop />}
      </div>
    );
  }

  render() {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper elevation={3}>
              <Box mx="auto" bgcolor="background.paper">
                {this.renderExaminationDetails()}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3}>
              <Box m="auto" bgcolor="background.paper">
                {this.renderProfileDetails()}
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            {this.renderNavbar()}
          </Grid>
        </Grid>
      </>
    );
  }
}

const styles = (theme) => ({
  examinationDetailsLink: {
    padding: 12,
    textAlign: "center",
    color: theme.palette.text.secondary,
    cursor: "pointer",
  },
  examinationDetailsTabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    width: 200,
  },
  examinationDetailsTabsRoot: {
    flexGrow: 1,
    display: "flex",
    height: 200,
  },
  examinationDetailsTabsSpacing: {
    marginBottom: theme.spacing(5),
  },
  examinationDetailCenter: {
    textAlign: "center",
    padding: theme.spacing(1),
  },
});

export default withStyles(styles)(withRouter(injectIntl(connectComponent(ExaminationDetails))));
