import { Component } from "react";
import { connectComponent } from "../actions/ActionUtils";

class Home extends Component {
  componentDidMount() {
    this.props.history.push(this.defaultTabHref());
  }

  defaultTabHref = () => {
    const examinationHref = "/examinations";
    const profileHref = "/profiles";
    const defaultTabHrefPerRole = {
      ADMIN: examinationHref,
      DOCTOR: profileHref,
      BASIC: examinationHref,
    };
    const defaultHrefForRole = defaultTabHrefPerRole[this.props.user.currentGroup.name];
    if (defaultHrefForRole) {
      return defaultHrefForRole;
    } else {
      return examinationHref;
    }
  };

  render() {
    return null;
  }
}

export default connectComponent(Home);
