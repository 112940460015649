import { createMuiTheme } from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3A2EB8",
      light: "#B9B4ED",
      dark: "#2E2496",
    },
    secondary: {
      main: "#49C700",
      dark: "#11Ab00",
      light: "#A3F772",
    },
  },
  typography: {
    fontFamily: "Averta",
    fontWeightBold: "Averta",
  },
  overrides: {
    MuiTable: {
      root: {
        minWidth: 650,
      },
    },
  },
});

theme.typography.h6 = {
  fontSize: "1rem",
};

theme.typography.body1 = {
  fontSize: "1rem",
  [theme.breakpoints.down("lg")]: {
    fontSize: "0.875rem",
  },
};

export default theme;
